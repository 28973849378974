import { Flex, Box, Text, Heading, Stack, Button } from '@chakra-ui/core'
import * as Sentry from '@sentry/browser'
import React from 'react'

type ErrorMessageProps = {
  title?: string
  message?: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ title, message }) => {
  return (
    <Flex width="100vw" height="100vh" align="center" justify="center">
      <Box bg="seastone" maxW="400px" py={4} px={6} flex={1}>
        <Stack spacing={4}>
          <Heading size="sm" mb={2}>
            {title ? title : 'Something went wrong.'}
          </Heading>
          {message && <Text size="md">{message}</Text>}
          <a aria-label="home page" href="/">
            <Button variant="back" mt={8}>
              Go Home
            </Button>
          </a>
        </Stack>
      </Box>
    </Flex>
  )
}

type ErrorBoundaryProps = {}

type ErrorBoundaryState = {
  hasError: boolean
  message?: string
  title?: string
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    console.log('error', error, typeof error)
    // Update state so the next render will show the fallback UI.
    return { hasError: true, message: error.message, title: error.title }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
    Sentry.captureException(error)
  }

  render() {
    const { title, message, hasError } = this.state
    if (hasError) {
      // You can render any custom fallback UI
      return <ErrorMessage title={title || ''} message={message} />
    }

    return this.props.children
  }
}
