import { COOKIE_LANG_KEY } from '@pangaea-holdings/pangaea-auth'
import {
  getSerializedCookie,
  setCookie,
} from '@pangaea-holdings/pangaea-checkout'
import universalLanguageDetect, {
  COOKIE_LOOKUP_KEY_LANG,
} from '@unly/universal-language-detector'
import { NextPageContext } from 'next'
import NextCookies from 'next-cookies'

import { SUPPORTED_LANGUAGES, FALLBACK_LANGUAGE } from '../config'

const normalizeLanguageAndLocale = (lang: string): string => {
  let normalizedLang = lang
  const splittedLang = lang.split(/-|_/g)
  const [language, locale] = splittedLang
  const formattedLocale = locale ? `-${locale.toUpperCase()}` : ''

  normalizedLang = `${language?.toLowerCase()}${formattedLocale}`

  return normalizedLang
}

const hasPreselectedLanguage = (
  queryLang: string,
  cookies: Record<
    typeof COOKIE_LOOKUP_KEY_LANG | typeof COOKIE_LANG_KEY,
    string | undefined
  >,
  res
): string | undefined => {
  const { i18next, language } = cookies

  let langPreset
  if (queryLang && SUPPORTED_LANGUAGES.includes(queryLang)) {
    langPreset = queryLang
  } else if (i18next || language) {
    langPreset = i18next || language
  }

  if (res) {
    res.setHeader('Set-Cookie', [
      getSerializedCookie(COOKIE_LANG_KEY, langPreset, 365),
      getSerializedCookie(COOKIE_LOOKUP_KEY_LANG, langPreset, 365),
    ])
  }
  if (process.browser) {
    setCookie(COOKIE_LOOKUP_KEY_LANG, langPreset, 365)
    setCookie(COOKIE_LANG_KEY, langPreset, 365)
  }

  return langPreset
}

const getLanguageCode = (languageCode: string): string => {
  const languageMap = {
    english: 'en',
    spanish: 'es',
    french: 'fr',
    traditionalChinise: 'zh-TW',
    german: 'de',
    arabic: 'ar',
    italian: 'it',
    portuguese: 'pt',
    indonesia: 'id',
    hebrew: 'he',
  }
  return languageMap[languageCode] || languageCode
}

/**
 *
 * Detects the language based in the following order
 * 1. Query param (lang)
 * 2. Cookie (i18next)
 * 3. Browser navigator
 */
function detectLanguage(ctx: NextPageContext): string {
  const { req, res } = ctx
  const cookies = NextCookies(ctx) // Parses Next.js cookies in a universal way (server + client) - It's an object

  let acceptLang
  if (req && req.headers) {
    acceptLang = req.headers['accept-language']
  }

  // give priority to language query param first
  const normalizedLang = normalizeLanguageAndLocale(
    String(ctx.query.language || ctx.query.lang)
  )
  const queryLang: string = getLanguageCode(normalizedLang)

  const langPreset = hasPreselectedLanguage(queryLang, cookies, res)

  if (langPreset) {
    return langPreset
  }

  // Universally detects the user's language
  // but not locale, this will be a fallback if there is noPreselectedLang
  // in cookies
  const lang = universalLanguageDetect({
    supportedLanguages: SUPPORTED_LANGUAGES, // Whitelist of supported languages, will be used to filter out languages that aren't supported
    fallbackLanguage: FALLBACK_LANGUAGE, // Fallback language in case the user's language cannot be resolved
    acceptLanguageHeader: acceptLang,
    serverCookies: cookies, // Optional - Cookie "i18next" takes precedence over navigator configuration (ex: "i18next: fr"), will only be used on the server side
    errorHandler: (error, level, origin, context) => {
      console.error('error detecting language', error)
    },
  })
  return lang
}

export default detectLanguage
