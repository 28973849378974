export enum ACTIONS_TYPES {
  CLEAR_QUIZ_CHOICES = 'CLEAR_QUIZ_CHOICES',
  SET_SELECTED_SINGLE_CHOICE = 'SET_SELECTED_SINGLE_CHOICE',
  SET_SELECTED_MULTI_CHOICE = 'SET_SELECTED_MULTI_CHOICE',
}

export interface SetSelectedChoiceAction {
  type: ACTIONS_TYPES.SET_SELECTED_SINGLE_CHOICE | ACTIONS_TYPES.SET_SELECTED_MULTI_CHOICE 
  payload: {
    quizSetId: string
    category: string
    choiceId: string
  }
}

export interface ClearQuizChoices {
  type: ACTIONS_TYPES.CLEAR_QUIZ_CHOICES
}

export type PersonalizedQuizActionTypes = SetSelectedChoiceAction | ClearQuizChoices
