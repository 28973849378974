const typography = {
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    tight: '1.2',
    base: '1.5',
    taller: '2',

    headingDisplay: '1.146em',
    headingLg: '.963em',
    headingMd: '1.25em',
    headingSm: '1.333em',
    headingXs: '1.4em',
    subheadingLg: '1.5em',
    subheadingMd: '1.4em',
    subheadingSm: '1.4em',
    subheadingXs: '1.4em',

    bodyLg: '1.5em',
    bodyMd: '1.5em',
    bodySm: '1.5em',
    bodyXs: '1.5em',

    headingDisplayMobile: '1.25em',
    headingLgMobile: '1em',
    headingMdMobile: '1.125',
    headingSmMobile: '1.125',
    headingXsMobile: '1.125',
    subheadingLgMobile: '1.4em',
    subheadingMdMobile: '1.4em',
    subheadingSmMobile: '1.4em',
    subheadingXsMobile: '1.4em',

    bodyLgMobile: '1.5em',
    bodyMdMobile: '1.7em',
    bodySmMobile: '1.7em',
    bodyXsMobile: '1.7em',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  fonts: {
    serif: 'freight-display-pro, serif',
    heading: 'freight-display-pro, serif',
    subheading: `FF Bau Regular, sans-serif`,
    medium: `FF Bau Medium, sans-serif`,
    body: `FF Bau Regular, sans-serif`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
  },
  fontSizes: {
    headingDisplay: '6rem',
    headingLg: '3.375rem',
    headingMd: '3.0rem',
    headingSm: '2.25rem',
    headingXs: '1.5rem',
    subheadingLg: '1.5rem',
    subheadingMd: '1.25rem',
    subheadingSm: '1.0rem',
    subheadingXs: '.875rem',

    bodyLg: '1.25rem',
    bodyMd: '1.0rem',
    bodySm: '0.8125rem',
    bodyXs: '0.6875rem',

    headingDisplayMobile: '4rem',
    headingLgMobile: '3rem',
    headingMdMobile: '2.5rem',
    headingSmMobile: '2.0rem',
    headingXsMobile: '1.0rem',
    subheadingLgMobile: '1.125rem',
    subheadingMdMobile: '1.0rem',
    subheadingSmMobile: '.875rem',
    subheadingXsMobile: '.875rem',

    bodyLgMobile: '1rem',
    bodyMdMobile: '.8125rem',
    bodySmMobile: '0.6875rem',
    bodyXsMobile: '0.6875rem',

    formLg: '1.25rem',
    formLgMobile: '1rem',
    formMd: '.875rem',
    formMdMobile: '.875rem',
    formSm: '.75rem',
    formSmMobile: '.75rem',

    buttonLgMobile: '.875rem',
    buttonMdMobile: '.8125rem',
    buttonSmMobile: '.75rem',

    buttonLg: '1rem',
    buttonMd: '.875rem',
    buttonSm: '.75rem',

    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
}

export default typography
