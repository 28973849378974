export const loadStyle = (() => {
  // Index to only load the same script once.
  const loadedStyle: { [src: string]: Promise<void> } = {}

  return async (
    href: string,
    rel = 'stylesheet'
  ): Promise<void> => {
    if (!loadedStyle[href]) {
      loadedStyle[href] = new Promise((resolve) => {
        const styleLink: any = document.createElement('link')
        styleLink.type = 'text/css'
        styleLink.href = href
        styleLink.rel = rel
        const head = document.getElementsByTagName('head')[0];
        styleLink.setAttribute("defer", "");
        head.appendChild(styleLink);
        resolve()
      })
    }

    return loadedStyle[href]
  }
})()
