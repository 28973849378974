import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { loadStyle } from 'utils/loadStyle'

export const useLoadDatepickerCss = () => {
  const router = useRouter()
  useEffect(() => {
    const path = router.asPath
    if (
      path.indexOf('/pages/accounts') !== -1 ||
      path.indexOf('/gift-card') !== -1
    ) {
      loadStyle('/css/react-datepicker.min.css')
    }
  }, [router.asPath])
}
