import {
  ACTIONS_TYPES,
  QuizActionTypes,
} from '../actionTypes'
import { AnsweredQuestion } from '../types'

const answeredQuestionInitialState: AnsweredQuestion = {
  skin_concern: false,
  skin_type: false,
  age: false,
}

export default function answeredQuestionsReducer(
  state = answeredQuestionInitialState,
  action: QuizActionTypes,
): AnsweredQuestion {
  switch (action.type) {
    case ACTIONS_TYPES.SELECT_ANSWERED_QUESTION: {
      const { category } = action.payload

      return { ...state, [category]: true }
    }
    default: {
      return state
    }
  }
}
