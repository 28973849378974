import Head from 'next/head';
import React, { useRef } from 'react';

import config from '../../../core/config';
import { useDisableAnalytics } from '../hooks/useDisableAnalytics';

interface ManualAnalyticsTagsProps {}

const ManualAnalyticsTags: React.FC<ManualAnalyticsTagsProps> = () => {
  const segmentId = config('SEGMENT_ID')
  const amplitudeId = config('AMPLITUDE_ID')
  const shopifyPermanentUrl = config('SHOPIFY_PERMANENT_URL')
  const pangaeaAnalyticsUrl = config('PANGAEA_ANALYTICS_SCRIPT_URL')  

  const disableAnalytics = useDisableAnalytics()

  const isLoaded = useRef(false)

  // Prevent these scripts from loading multiple times
  if (isLoaded.current || disableAnalytics) {
    return null
  }
  isLoaded.current = true

  return (
    <>
      {segmentId && (
        <Head key="segment_script">
          <script
            data-ot-ignore="true"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
              !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentId}";;analytics.SNIPPET_VERSION="4.15.3";
              analytics.load("${segmentId}");
              analytics.page();
              }}();
            `,
            }}
          />
        </Head>
      )}

      {amplitudeId && (
        <Head key="amplitude_script">
          <script
            data-ot-ignore="true"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
              !function(){"use strict";!function(e,t){var r=e.amplitude||{_q:[],_iq:{}};if(r.invoked)e.console&&console.error&&console.error("Amplitude snippet has been loaded.");else{var n=function(e,t){e.prototype[t]=function(){return this._q.push({name:t,args:Array.prototype.slice.call(arguments,0)}),this}},s=function(e,t,r){return function(n){e._q.push({name:t,args:Array.prototype.slice.call(r,0),resolve:n})}},o=function(e,t,r){e._q.push({name:t,args:Array.prototype.slice.call(r,0)})},i=function(e,t,r){e[t]=function(){if(r)return{promise:new Promise(s(e,t,Array.prototype.slice.call(arguments)))};o(e,t,Array.prototype.slice.call(arguments))}},a=function(e){for(var t=0;t<g.length;t++)i(e,g[t],!1);for(var r=0;r<m.length;r++)i(e,m[r],!0)};r.invoked=!0;var c=t.createElement("script");c.type="text/javascript",c.integrity="sha384-Qht2PEamEOgBr0T73d2XgDN5WYZGrhAy2nyMa193Xra/hWrydUQY4qlwK620MTXj",c.crossOrigin="anonymous",c.async=!0,c.src="https://cdn.amplitude.com/libs/analytics-browser-2.6.3-beta.0-min.js.gz",c.onload=function(){e.amplitude.runQueuedFunctions||console.log("[Amplitude] Error: could not load SDK")};var u=t.getElementsByTagName("script")[0];u.parentNode.insertBefore(c,u);for(var l=function(){return this._q=[],this},p=["add","append","clearAll","prepend","set","setOnce","unset","preInsert","postInsert","remove","getUserProperties"],d=0;d<p.length;d++)n(l,p[d]);r.Identify=l;for(var f=function(){return this._q=[],this},y=["getEventProperties","setProductId","setQuantity","setPrice","setRevenue","setRevenueType","setEventProperties"],v=0;v<y.length;v++)n(f,y[v]);r.Revenue=f;var g=["getDeviceId","setDeviceId","getSessionId","setSessionId","getUserId","setUserId","setOptOut","setTransport","reset","extendSession"],m=["init","add","remove","track","logEvent","identify","groupIdentify","setGroup","revenue","flush"];a(r),r.createInstance=function(e){return r._iq[e]={_q:[]},a(r._iq[e]),r._iq[e]},e.amplitude=r}}(window,document)}();
                  amplitude.init("${amplitudeId}", {
                    defaultTracking: false,
                  });
            `,
            }}
          />
        </Head>
      )}

      {shopifyPermanentUrl && pangaeaAnalyticsUrl && (
        <Head key="pangaea_analytics_script">
          <script
            data-ot-ignore="true"
            id="pgAnalyticsScript"
            data-store={shopifyPermanentUrl}
            type="text/javascript"
            src={pangaeaAnalyticsUrl}
            data-app-url={config('SHOPIFY_APP_URL')}
            async
          />
        </Head>
      )}
    </>
  )
}

export default ManualAnalyticsTags
