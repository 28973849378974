import { PersonalizedQuizActionTypes, ACTIONS_TYPES } from './actionTypes'
import { PersonalizedQuizState } from './types'

const initialState: PersonalizedQuizState = {}

export const quizChoiceReducer = (
  state = initialState,
  action: PersonalizedQuizActionTypes
): PersonalizedQuizState => {
  switch (action.type) {
    case ACTIONS_TYPES.CLEAR_QUIZ_CHOICES:
      return {
        ...initialState
      }

    case ACTIONS_TYPES.SET_SELECTED_SINGLE_CHOICE: {
      const { quizSetId, category, choiceId } = action.payload
      return {
        ...state,
        [quizSetId]: {
          ...state[quizSetId],
          [category]: choiceId,
        },
      }
    }
    case ACTIONS_TYPES.SET_SELECTED_MULTI_CHOICE: {
      const { quizSetId, category, choiceId } = action.payload
      const currentChoice = (state[quizSetId]?.[category] as string[]) || []
      const updatedChoice = [...currentChoice]
      const index = updatedChoice.findIndex((value) => value == choiceId)
      if (index > -1) {
        updatedChoice.splice(index, 1)
      } else {
        updatedChoice.push(choiceId)
      }
      return {
        ...state,
        [quizSetId]: {
          ...state[quizSetId],
          [category]: updatedChoice,
        },
      }
    }
    default:
      return state
  }
}
