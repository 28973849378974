import { layerPush } from '../analytics/functions/track'

import { BusinessModel } from 'modules/shared/types'

enum EVENTS {
  EXPERIMENT_ACTIVATED = 'business_model_experiment_activated',
}

export const trackBusinessModelExperimentActivated = (
  experimentName: string,
  businessModel: BusinessModel
) => {
  layerPush({
    event: EVENTS.EXPERIMENT_ACTIVATED,
    experiment_api_name: experimentName,
    user_business_model: businessModel,
  })
}
