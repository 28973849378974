import { getDefaultThemeComponents } from '@pangaea-holdings/pangaea-checkout'

import Form from '../components/Form'
import { Heading } from '../components/Heading'
import { Input } from '../components/Input'
import Modal from '../components/Modal'
import { Select } from '../components/Select'
import { Text } from '../components/Text'
import { Button } from './Button'

const themeComponents = getDefaultThemeComponents({
  Button,
  Input,
  Select,
  Text,
})

export const components = {
  Heading,
  Form,
  Modal,
  ...themeComponents,
}
