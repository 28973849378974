import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React from 'react'

const AccountSystemTouchpointQuiz = dynamic(
  () => import('modules/account/components/AccountSystemTouchpointQuiz'),
  { ssr: false }
)
const HowToTouchpointQuiz = dynamic(
  () => import('modules/howTo/components/HowToTouchpointQuiz'),
  { ssr: false }
)

const PageSurvey: React.FC = () => {
  const { pathname } = useRouter()

  if (pathname.startsWith('/pages/how-to')) {
    return <HowToTouchpointQuiz />
  } else if (pathname.startsWith('/pages/accounts')) {
    return <AccountSystemTouchpointQuiz />
  }

  return null
}

export default PageSurvey
