import Head from 'next/head'
import React, { useRef } from 'react'

import config from '../../../core/config'

const PandectesScriptTag: React.FC<{ store: string }> = ({
  store = 'next',
}) => {
  const storeId = config('PANDECTES_STORE_ID')
  const isLoaded = useRef(false)
  const isShopifyStore = store === 'shopify'

  // Prevent these scripts from loading multiple times
  if (isLoaded.current) {
    return null
  }
  isLoaded.current = true

  if (!isShopifyStore) {
    return null
  }

  return (
    <>
      <Head key="pandectes_script">
        {storeId && (
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
              !function(){var t=[
                "https://st.pandect.es/meridiangrooming/pandectes-rules.js",
                "https://s.pandect.es/c/pandectes-core.js?storeId=${storeId}"]
                ;function e(t,e){var a=document.createElement("script");
                a.src=t,a.async=!0,a.type="text/javascript",e&&(a.onload=e);
                var s=document.getElementsByTagName("script")[0];s.parentNode.insertBefore(a,s)}
                var a=function(){e(t[0],function(){e(t[1])})};
                window.attachEvent?window.attachEvent("onload",a):window.addEventListener("load",a,!1)}();
           `,
            }}
          />
        )}
      </Head>
    </>
  )
}

export default PandectesScriptTag
