import { Button } from './Button'
import Form from './Form'
import { Heading } from './Heading'
import { Input } from './Input'
import Modal from './Modal'
import { Select } from './Select'
import { Text } from './Text'
import { themeComponents } from './themeComponents'

export const components = {
  Button,
  Heading,
  Text,
  Input,
  Select,
  Form,
  Modal,
  ...themeComponents,
}
