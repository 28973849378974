import shopifyTheme, { ShopifyTheme } from './shopify-theme/theme'
import nextTheme, { LuminTheme } from './theme'

const getAppTheme = (
  theme: 'next' | 'shopify' = 'next'
): LuminTheme | ShopifyTheme => {
  switch (theme) {
    case 'shopify': {
      return { ...nextTheme, ...shopifyTheme }
    }
    case 'next':
    default: {
      return nextTheme
    }
  }
}

export default getAppTheme
