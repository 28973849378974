const defaultProps = {
  variant: 'primary',
  size: 'md',
}

export const Button = {
  defaultProps,
  baseStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    lineHeight: 'tight',
    pt: '2px',
    fontWeight: 'bold',
    fontFamily: 'body',
    px: 4,
  },

  sizes: {
    xl: {
      minHeight: ['heightXlMobile', null, 'heightXl'],
      fontSize: ['buttonLgMobile', null, 'buttonLg'],
    },

    lg: {
      minHeight: ['heightLg'],
      fontSize: ['buttonLgMobile', null, 'buttonLg'],
    },

    md: {
      minHeight: 'heightMd',
      fontSize: ['buttonMdMobile', null, 'buttonMd'],
    },
    sm: {
      minHeight: ['heightSmMobile', null, 'heightSm'],
      fontSize: ['buttonSmMobile', null, 'buttonSm'],
    },
  },

  colorSchemes: {
    sage: {
      bg: 'sage',
      color: 'whiteSands',
      _hover: {
        bg: 'nightGreen',
      },
      _disabled: {
        bg: 'disabled',
      },
    },
  },
  variants: {
    primary: {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      bg: 'moss',
      color: 'whiteSands',
      _hover: {
        bg: 'nightGreen',
      },
      _disabled: {
        bg: 'moss', // disabled color doesn't contrast well. The lower opacity is good enough for visual feedback
      },
    },
    secondary: {
      width: '100%',
      py: 3,
      px: 6,
      bg: 'transparent',
      color: 'buttonText',
      border: 'seastone',
      _hover: {
        bg: 'sage',
        color: 'whiteSands',
        border: 'sage',
      },
      _disabled: {
        bg: 'disabled',
        color: 'whiteSands',
        border: 'disabled',
      },
    },

    // 'primary-sage': {
    //   container: {
    //     bg: 'sage',
    //     color: 'whiteSands',
    //     _hover: {
    //       bg: 'nightGreen',
    //     },
    //     _disabled: {
    //       bg: 'disabled',
    //     },
    //   },
    // },

    outline: {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      bg: 'transparent',
      border: 'nightGreen',
      color: 'buttonText',
      _hover: {
        bg: 'nightGreen',
        color: 'whiteSands',
      },
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },

    back: {
      width: ['100%', null, 'auto'],
      minWidth: ['100%', null, '190px'],
      bg: 'transparent',
      border: 'nightGreen',
      color: 'buttonText',
      _hover: {
        bg: 'nightGreen',
        color: 'whiteSands',
      },
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },

    'outline-shower': {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      bg: 'transparent',
      border: 'moss',
      color: 'buttonText',
      _hover: {
        bgColor: 'offwhite',
      },
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
      _active: {
        border: '1.5px solid',
        bgColor: 'offwhite',
      },
      _focus: {
        border: '1.5px solid',
        bgColor: 'offwhite',
      },
      _selected: {
        bg: 'showers',
      },
    },

    'outline-white': {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      bg: 'transparent',
      border: 'whiteSands',
      color: 'whiteSands',
      _hover: {
        color: 'moss',
        border: 'moss',
      },
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },
    'outline-white-no-hover': {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      bg: 'transparent',
      border: 'whiteSands',
      color: 'whiteSands',
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },
    'outline-grey': {
      width: '100%',
      py: 3,
      px: 6,
      bg: 'transparent',
      color: 'sage',
      border: 'seastone',
      _hover: {
        border: 'nightGreen',
        color: 'buttonText',
      },
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },
    'outline-grey-no-hover': {
      width: '100%',
      py: 3,
      px: 6,
      bg: 'transparent',
      color: 'sage',
      border: 'seastone',
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },
    blank: {
      color:"moss",
      display: 'inline-block',
      border: 'none',
      lineHeight: 'inherit',
      minWidth: 'auto',
      minHeight: 'auto',
      width: 'auto',
      height: 'auto',
      // width: ['auto', 'auto', 'auto'],
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      pt: 0,
      px: 0,
      p: 0,
      background: 'none',
      _disabled: {
        color: 'lightFoam2',
      },
    },
    'shopify-outline': {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      bg: 'white',
      borderColor: 'black',
      borderWidth: '0.5px',
      borderStyle: 'solid',
      borderRadius: '4px',
      color: 'black',
      _hover: {
        bg: 'black',
        color: 'white',
      },
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },
    'post-purchase-banner': {
      width: '100%',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      bg: 'disabled',
      color: 'black',
      _disabled: {
        bg: 'moss',
      },
    },
  },
}
