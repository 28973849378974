import { Product } from '@pangaea-holdings/pangaea-checkout'

import { ProductsActionTypes } from './actionTypes'

export type ProductsReducerState = {
  subDiscountKeys?: string[] | null
  all: {
    [currency: string]: Product[]
  }
  single: {
    [currency: string]: Product[]
  }
  catalogs: {
    [countryAndCurrency: string]: Product[]
  }
}

const initialState: ProductsReducerState = {
  subDiscountKeys: null,
  all: {},
  single: {},
  catalogs: {},
}

export function productsReducer(
  state = initialState,
  action: ProductsActionTypes
): ProductsReducerState {
  switch (action.type) {
    case 'PRODUCTS_LOAD_ALL':
      return loadAllProduct(
        state,
        action.payload.currency,
        action.payload.products,
        action.payload.countryCode,
        action.payload.subDiscountKeys
      )
    case 'PRODUCTS_LOAD_CATALOG':
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          [action.payload.catalogKey]: action.payload.products,
        },
      }
    case 'PRODUCTS_LOAD_MULTIPLE':
      return loadMultipleProducts(
        state,
        action.payload.currency,
        action.payload.products
      )

    case 'PRODUCTS_LOAD_SINGLE':
      return {
        ...loadSingleProduct(
          state,
          action.payload.currency,
          action.payload.product
        ),
        subDiscountKeys: action.payload.subDiscountKeys,
      }
    default:
      return state
  }
}

function loadAllProduct(
  state: ProductsReducerState,
  currency: string,
  products: Product[],
  countryCode: string | undefined,
  subDiscountKeys: string[] | undefined
) {
  const key = countryCode ? `${currency}:${countryCode}` : currency
  return {
    ...state,
    all: {
      ...state.all,
      [key]: products,
    },
    subDiscountKeys: subDiscountKeys,
  }
}

function loadSingleProduct(
  state: ProductsReducerState,
  currency: string,
  product: Product
): ProductsReducerState {
  const { single } = state
  single[currency] = single[currency] || []
  const ind = single[currency].findIndex((a) => a.id === product.id)
  if (ind > -1) {
    single[currency][ind] = product
  } else {
    single[currency].push(product)
  }

  return {
    ...state,
    single,
  }
}

function loadMultipleProducts(
  state: ProductsReducerState,
  currency: string,
  products: Product[]
): ProductsReducerState {
  return products.reduce<ProductsReducerState>((carry, item) => {
    return loadSingleProduct(carry, currency, item)
  }, state)
}
