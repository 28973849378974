export enum ACTIONS_TYPES {
  CHOOSE_MULTI_CHOICE_ANSWER = 'CHOOSE_MULTI_CHOICE_ANSWER',
  CHOOSE_SINGLE_CHOICE_ANSWER = 'CHOOSE_SINGLE_CHOICE_ANSWER',
  SELECT_ANSWERED_QUESTION = 'SELECT_ANSWERED_QUESTION',
  ENABLE_SINGLE_FREE_TRIAL_BUNDLE = 'ENABLE_SINGLE_FREE_TRIAL_BUNDLE',
  DISABLE_SINGLE_FREE_TRIAL_BUNDLE = 'DISABLE_SINGLE_FREE_TRIAL_BUNDLE',
}

import {
  MultiChoiceCategory,
  SingleChoiceCategory,
  QuizCategory,
  QuizChoice,
} from './types'

export interface ChooseMultiChoiceAnswerAction {
  type: ACTIONS_TYPES.CHOOSE_MULTI_CHOICE_ANSWER,
  payload: {
    choice: QuizChoice
    category: MultiChoiceCategory
  }
}

export interface ChooseSingleChoiceAnswerAction {
  type: ACTIONS_TYPES.CHOOSE_SINGLE_CHOICE_ANSWER
  payload: {
    choice: QuizChoice
    category: SingleChoiceCategory
  }
}

export interface SelectAnsweredQuestionAction {
  type: ACTIONS_TYPES.SELECT_ANSWERED_QUESTION
  payload: {
    category: QuizCategory
  }
}

export interface EnableSingleFreeTrialAction {
  type: ACTIONS_TYPES.ENABLE_SINGLE_FREE_TRIAL_BUNDLE
}

export interface DisableSingleFreeTrialAction {
  type: ACTIONS_TYPES.DISABLE_SINGLE_FREE_TRIAL_BUNDLE
}

export type QuizActionTypes =
  | ChooseMultiChoiceAnswerAction
  | ChooseSingleChoiceAnswerAction
  | SelectAnsweredQuestionAction
  | EnableSingleFreeTrialAction
  | DisableSingleFreeTrialAction
