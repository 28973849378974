import {
  ACTIONS_TYPES,
  QuizActionTypes,
} from '../actionTypes'
import { QuizAnswers } from '../types'

const quizAnswersInitialState: QuizAnswers = {
  skin_concern: [],
  skin_type: null,
  age: null,
}

export default function quizAnswersReducer(
  state = quizAnswersInitialState,
  action: QuizActionTypes,
): QuizAnswers {
  switch (action.type) {
    case ACTIONS_TYPES.CHOOSE_MULTI_CHOICE_ANSWER: {
      const { category, choice } = action.payload

      if (state[category].length) {
        const index = state[category].findIndex(
          (multiChoice) => multiChoice.id === choice.id
        )

        if (index !== -1) {
          return {
            ...state,
            [category]: [
              ...state[category].slice(0, index),
              ...state[category].slice(index + 1),
            ],
          }
        } else {
          return {
            ...state,
            [category]: [...state[category], choice],
          }
        }
      } else {
        return {
          ...state,
          [category]: [choice],
        }
      }
    }
    case ACTIONS_TYPES.CHOOSE_SINGLE_CHOICE_ANSWER: {
      const { category, choice } = action.payload

      return { ...state, [category]: choice }
    }
    default: {
      return state
    }
  }
}
