export enum ORDER_CONFIRMATION_ACTIONS {
  SHOW_POST_PURCHASE_OFFERS = 'SHOW_POST_PURCHASE_OFFERS',
}

export interface ShowPostPurchaseOffers {
  type: ORDER_CONFIRMATION_ACTIONS.SHOW_POST_PURCHASE_OFFERS
  payload: boolean
}

export type OrderConfirmationActionTypes = ShowPostPurchaseOffers
