import { trackBusinessModelExperimentActivated } from './track'

import { NewBusinessModelExperimentPageName } from 'modules/shared/constants/general'
import { BusinessModel } from 'modules/shared/types'
import { AppState, ThunkActionCreator } from 'redux/rootReducer'

export enum BUSINESS_MODEL_ACTION {
  SET_BUSINESS_MODEL = 'set_business_model',
}

export type BusinessModelActionTypes = {
  type: BUSINESS_MODEL_ACTION
  payload: BusinessModel | null
}

export const setUserBusinessModel: ThunkActionCreator<Promise<void>> =
  (model: BusinessModel | null) => async (dispatch) => {
    dispatch({
      type: BUSINESS_MODEL_ACTION.SET_BUSINESS_MODEL,
      payload: model,
    })
    if (model) {
      trackBusinessModelExperimentActivated(
        NewBusinessModelExperimentPageName,
        model
      )
    }
  }

export const selectUserBusinessModel = (state: AppState) =>
  state.businessModelState
