import { useEffect } from 'react'

import { useTypedDispatch } from '../../../redux/store'
import { loadAuthFromCookie } from '../actions'

export const useAccountAuth = () => {
  const dispatch = useTypedDispatch()

  useEffect(() => {
    dispatch(loadAuthFromCookie())
  }, [dispatch])
}
