import { BusinessModelActionTypes, BUSINESS_MODEL_ACTION } from './actions'

import { BusinessModel } from 'modules/shared/types'

export function businessModelReducer(
  state = null,
  action: BusinessModelActionTypes
): BusinessModel | null {
  switch (action.type) {
    case BUSINESS_MODEL_ACTION.SET_BUSINESS_MODEL:
      return action.payload
    default:
      return state
  }
}
