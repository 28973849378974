// Country code to be considered when we can't determine from the geoIp service.
// Set it to null if you want to let the backend decide.
export const DEFAULT_COUNTRY_CODE = 'US'

export const NewBusinessModelExperimentPageName = '20221205_New_Business_Model'

export const genericBlurDataUri =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8+OxhPQAIgQMpEWA5yAAAAABJRU5ErkJggg=='

export const returnPolicyPage =
  'https://luminskin.zendesk.com/hc/en-us/articles/16157531460249'

export const TIER1_COUNTRIES = [
  'US',
  'CH',
  'PR',
  'NO',
  'FR',
  'SG',
  'DK',
  'IS',
  'LU',
  'SA',
  'HR',
]

export const TIER2_COUNTRIES = [
  'SE',
  'BE',
  'AT',
  'CA',
  'GB',
  'AU',
  'DE',
  'NL',
  'HK',
  'ES',
  'FI',
  'TW',
  'IL',
  'MY',
  'NZ',
  'IT',
  'PT',
  'PL',
  'JP',
  'CZ',
  'GR',
  'HU',
  'SI',
  'RS',
  'EE',
  'RO',
  'LV',
  'SK',
  'KR',
]
