import typography from '../typography'
import { components } from './components'

const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000',
  white: '#fff',
  disabled: '#F2F1ED',
  secondary: '#696969',

  primaryBgColor: '#FCDC00',
  primaryBgTextColor: '#000',

  // brand specific
  textPrimary: '#1E2D2B',
  textSecondary: '#A8A8A8',
  drawerText: '#0B0C0D',
  buttonText: '#0A0E0D',
  nightGreen: '#2b2e2b',
  moss: '#FCDC00',
  sage: 'black',
  seastone: '#cdd1ce',
  seastone2: '#e0e2e0',
  foam: '#e2e6e3',
  foam2: '#e9ebe7',
  lightFoam: '#ECECEC',
  lightFoam2: '#D9DDDA',
  orange: '#FEBC47',
  charcoal: '#000000',
  offwhite: '#f5f5f4',
  offwhite2: '#f2f2ef',
  offwhite3: '#f7f7f7',
  whiteSands: '#fcfcf9',
  morning: '#9eacae',
  showers: '#cee2ea',
  mabel: '#CFE2EA',
  seastoneAplha80: 'rgba(205, 209, 206, 0.8)',
  timelineRadialBg: 'radial-gradient(closest-side, black, #fcfcf9)',
  darkTransparentOverlayBg: '#00000059',
  videoBgColor: '#494949',
  lightCyan: '#CFE2E9',
  limeGreen: '#499769',
  darkGrey: '#C4C4C4',
  transparentBlack: 'rgba(0, 0, 0, 0.6)',
  transparentBlack08: 'rgba(0, 0, 0, 0.8)',
  sunYellow: '#FCDC00',
  moonDust: '#F2F1ED',

  // form colors
  formError: '#dc3545',
  formHelperText: '#888',
  formIcon: '#2b2e2b',
  // Separator
  separator: '#5c5c5c',
  //badge
  tomato: '#f06570',
  // checkout
  sectionSeparator: '#202020',
  laurelGreen: '#258d03',
  checkoutText: '#152B2A',
  disabledNav: '#acacac',
  subHeading: '#999999',
  selectedAddressBg: 'rgba(110, 123, 112, 0.1)',
  modalBg: 'rgba(205, 209, 206,0.8)',
  checkMark: '#3d3f43',
  darkColor: '#1D2B2B',
  successBadgeColor: '#4D5549',
  seastoneBrightColor: '#DADDDA',
  grey: '#8B8B8B',
  lightGray: '#E5E5E5',
  lightGray2: '#F6F6F6',
  lightGreen: '#cdd1ce',
  lightGreen50: 'rgba(205, 209, 206, 0.5)',
  darkGray: '#707070',
  topNavDarkBorder: '#292929',
  greySelectBorder: '#ACAFAD',
  detailsText: '#525F53',
  seaMist: '#C6CCC7',
  spanishGray: '#959595',
  ivory: '#F4F4F3',
  willowGreen: '#6A7B56',
  mud: '#E3CAB7',
  paymentOption: '#616161',
  grapeFruit: '#E04713',
  grayPolicy: 'rgba(26, 26, 26, 0.9)',

  /* cart */
  cartCouponBorderColor: '#6E7B70',
  cartCouponInputBg: '#fff',
  cartCouponInputColor: '#489769',
  cartCouponApplyButtonBg: '#6E7B70',
  cartCouponApplyButtonTextColor: '#FFFFFF',

  /* checkout , please don't change or remove these values ,
   it's to unify color names used in checkout page colors across brands*/
  checkoutBgColor: '#f5f5f4',
  cartButtonColor: '#fcfcf9',
  amazonButtonColor: '#f3cd73',

  /** Account System */
  tertiaryTextColorAS: '#707b71',
  inputBgColor: '#6e7b70',
  arrowColor: '#6f7780',
  successColor: '#489769',
  brightGreen: '#1DB51D',
  darkGreen: '#2D392F',
  blackTitleColor: '#231F20',
  backLinkColor: '#848484',
  warningColor: '#b12704',
  editButtonColor: '#54725b',
  successMarkBorder: 'rgba(110,123,112,0.37)',
  cancelButtonBg: 'rgba(177,39,4,0.03)',
  activeFieldBgColor: 'rgba(110, 123, 112, 0.27)',
  placeholderColor: '#bcbcbc',
  wildSand: '#f4f4f4',
  itemBgColor: '#f6f7f9',
  fireFly: '#0E1E26',
  bundleBadgeColor: '#d2e1e9',
  infoBg: '#e3e6e3',
  cardBgColor: '#ecedf2',
  giftModalOverlayBgColor: 'rgba(110,123,112,.38)',
  giftDiscountBoxBgColor: 'rgba(86,99,88,.9)',
  sageShade4: 'rgba(110, 123, 112, 0.4)',
  darkTextColor: '#2B2E2B',
  lightGrey: '#C4C4C4',
  brightOrange: '#FDAF17',
  darkOverlayAlpha25: 'rgba(51, 51, 51, 0.25)',
  progressBarBg: '#ddddda',
}

const borders = {
  none: 0,
  '1px': '1px solid',
  '2px': '2px solid',
  '4px': '4px solid',
  disabled: `1px solid ${colors.disabled}`,
  nightGreen: `1px solid ${colors.nightGreen}`,
  whiteSands: `1px solid ${colors.whiteSands}`,
  seastone: `1px solid ${colors.seastone}`,
  moss: `1px solid ${colors.moss}`,
  morning: `1px solid ${colors.morning}`,
}

const globalStyles = {
  color: `rgb(30, 45, 43)`,
  fontFamily: 'body',
  'a:hover': {
    cursor: 'pointer',
  },
  '*': `@font-face {
    font-family: Helvetica;
    font-display: swap;
    src: url("https://cdn.shopify.com/s/files/1/2960/5204/files/helvetica?v=1701104003") format("woff2")
  }

  @font-face {
    font-family: Helvetica;
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    src: url("https://cdn.shopify.com/s/files/1/2960/5204/files/helvetica?v=1701104003") format("woff2")
  }

  @font-face {
    font-family: Helvetica;
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
    src: url("https://cdn.shopify.com/s/files/1/2960/5204/files/helvetica-bold?v=1701104003") format("woff2")
  }

  .d-none{
    display: none;
  }
  `,
}

const fonts = {
  serif: 'Helvetica, serif',
  heading: 'Helvetica, serif',
  subheading: `Helvetica, sans-serif`,
  medium: `Helvetica, sans-serif`,
  body: `Helvetica, sans-serif`,
  mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
}

const shopifyTypography = { ...typography, fonts }

const customShopifyTheme = {
  styles: {
    global: globalStyles,
  },
  ...shopifyTypography,
  borders,
  colors,
  components,
}

export type ShopifyTheme = typeof customShopifyTheme

export default customShopifyTheme
