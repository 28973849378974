import Script from 'next/script'
import React from 'react'

import config from '../../core/config'

const GoogleMapScriptTag = () => {
  const API_KEY = config('GOOGLE_API_KEY')

  if (!API_KEY) {
    return null
  }

  if (typeof window !== 'undefined' && window.google) {
    return null
  }

  return (
    <Script
      data-ot-ignore="true"
      src={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`}
      strategy="lazyOnload"
    />
  )
}

export default GoogleMapScriptTag
