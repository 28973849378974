export const SUPPORTED_LANGUAGES_FULL = {
  en: 'English',
  ar: 'عربي',
  fr: 'Français',
  es: 'Español',
  de: 'Deutsch',
  he: 'עִברִית',
  id: 'Indonesia',
  'zh-TW': '中國傳統的',
  pt: 'Português',
  th: 'Thai - แบบไทย',
  da: 'Dansk',
  ja: '日本',
  ko: '한국인',
}

export const SUPPORTED_LANGUAGES = [
  'ar',
  'fr',
  'en',
  'es',
  'de',
  'he',
  'id',
  'zh-TW',
  'pt',
  'th',
  'da',
  'ja',
  'ko',
]

export const FALLBACK_LANGUAGE = 'en'
export const DEFAULT_NS = 'default'
export const I18N_PROJECT = 'web'
export const I18N_BRAND = 'lumin'
