import * as Sentry from '@sentry/browser'

import { onOptimizelyCampaignDecided, optimizelyPush } from '../optimizely'
import { LIFECYCLE_EVENT, EventParameters } from './types'

import { trackExperimentActivated } from 'modules/optimizely/events'

class Lifecycle {
  private hasFirstLoad: boolean = false
  private isFirstPageView: boolean = true
  private handlers: {
    [key in LIFECYCLE_EVENT]?: (...args: any[]) => void
  } = {}

  constructor() {
    if (!process.browser) {
      return
    }

    optimizelyPush({
      type: 'addListener',
      filter: {
        type: 'lifecycle',
        name: 'pageActivated',
      },
      // Optimizely does not update the experiment state
      // (`optimizely.get('state')`) with the latest manually activated
      // experiment immediately after doing so.
      handler: () => {
        setTimeout(
          () => this.dispatchEvent('optimizelyPageActivated', undefined),
          250
        )
      },
    })

    optimizelyPush({
      type: 'addListener',
      filter: {
        type: 'lifecycle',
        name: 'campaignDecided',
      },
      // Fires each time the visitor is assigned a variation (or none) for a campaign or experiment.
      handler: (eventData) => {
        this.dispatchEvent('optimizelyCampaignDecided', eventData)
      },
    })
  }

  public registerListeners(listeners: {
    [key in LIFECYCLE_EVENT]?: (args: EventParameters[key]) => void
  }) {
    Object.keys(listeners).forEach((event: LIFECYCLE_EVENT) => {
      this.handlers[event] = listeners[event]
    })
  }

  /**
   * In order to centralize event handling, only a single listener can be
   * registered per event.
   */
  public registerListener<T extends LIFECYCLE_EVENT>(
    event: T,
    cb: (atts: EventParameters[T]) => void
  ) {
    if (this.handlers[event]) {
      throw new Error(`registering callback for ${event} more than once`)
    }

    this.handlers[event] = cb
  }

  private dispatchEvent<T extends LIFECYCLE_EVENT>(
    event: T,
    atts: EventParameters[T]
  ) {
    const cb = this.handlers[event]

    try {
      cb && cb(atts)
    } catch (e) {
      console.error(`An error ocurred handling lifecycle (event=${event})`)
      console.error(e)

      Sentry.captureException(e)
    }
  }

  handleFirstLoad() {
    if (this.hasFirstLoad) {
      return
    }

    this.dispatchEvent('firstLoad', this.isFirstPageView)
    this.hasFirstLoad = true
  }

  handleShippingAddressUpdated(
    atts: EventParameters['shippingAddressUpdated']
  ) {
    this.dispatchEvent('shippingAddressUpdated', atts)
  }

  handleOrderConfirmed(atts: EventParameters['orderConfirmed']) {
    this.dispatchEvent('orderConfirmed', atts)
  }

  handlePageview(atts: EventParameters['pageView']) {
    this.dispatchEvent('pageView', { ...atts, isFirst: this.isFirstPageView })
    this.isFirstPageView = false
  }

  handleSurveyCompleted(atts: EventParameters['surveyCompleted']) {
    this.dispatchEvent('surveyCompleted', atts)
  }

  handleExperimentActivated() {
    this.dispatchEvent('optimizelyPageActivated', undefined)
  }
}

const lifecycle = new Lifecycle()

export default lifecycle
