
import { removeCookie, getCookie } from '@pangaea-holdings/pangaea-checkout'

import { ThunkActionCreator } from '../../redux/rootReducer'
import { apiFetchJson } from '../api'
import { ACCOUNT_LOAD_AUTH, AccountLoadAuth } from './actionTypes'

type CookieDict = {
  [key: string]: string
}

export const loadAuth: ThunkActionCreator<void> = (cookies: CookieDict) => (
  dispatch
) => {
  const action: AccountLoadAuth = {
    type: ACCOUNT_LOAD_AUTH,
    payload: null,
  }

  if (cookies.userToken && cookies.userId) {
    action.payload = {
      token: cookies.userToken,
      userId: cookies.userId,
    }
  }

  dispatch(action)
}

export const submitLogin: ThunkActionCreator<Promise<any>> = (
  email: string
) => async (dispatch) => {
  return apiFetchJson(`/api/account/magic-link`, {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    body: `email=${encodeURIComponent(email)}`,
  })
}

export const signOut = () => {
  removeCookie('userToken')
  removeCookie('userId')
  window.location.reload()
}

export const loadAuthFromCookie: ThunkActionCreator<
  Promise<any>
> = () => async (dispatch) => {
  const token = getCookie('userToken')
  const userId = getCookie('userId')

  if (token && userId) {
    dispatch({
      type: ACCOUNT_LOAD_AUTH,
      payload: {
        token,
        userId,
      },
    })
  } else {
    // not logged in
    dispatch({
      type: ACCOUNT_LOAD_AUTH,
      payload: null,
    })
  }
}
