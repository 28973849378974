// Todo: Save and read this from db on service store front

export interface CurrencyData {
  currency: string
  country: string
  currency_code: string
  currency_symbol?: string
  flag?: string
}

export const CURRENCIES: { [countryCode: string]: CurrencyData } = {
  NZ: {
    currency: 'New Zealand Dollars',
    country: 'New Zealand',
    currency_code: 'NZD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/nz-067fcad818cb43f94a1b4912fc8aabba2ebf649c0012a155227b7b9d7bff5e2a.svg',
  },
  CK: {
    currency: 'New Zealand Dollars',
    country: 'Cook Islands',
    currency_code: 'NZD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/ck-8e6b1d3b6c387fadb92652588fee211d070cd282c0aa3fb0fbbd9e4b9ccbb542.svg',
  },
  NU: {
    currency: 'New Zealand Dollars',
    country: 'Niue',
    currency_code: 'NZD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/nu-7142fbe1478d82ebd2380fc0fe2acbadc34428bb4abc81776a5438094f7e8ac2.svg',
  },
  PN: {
    currency: 'New Zealand Dollars',
    country: 'Pitcairn',
    currency_code: 'NZD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/pn-2bcc3ca847019caa228a5819854684d463d2b60f25359dd49ceb47dc66f9ab28.svg',
  },
  TK: {
    currency: 'New Zealand Dollars',
    country: 'Tokelau',
    currency_code: 'NZD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/tk-53658460c1ddd4ecdf442a1eeec862bf1141183559d2603575c2fc7881b29c42.svg',
  },
  AU: {
    currency: 'Australian Dollars',
    country: 'Australian',
    currency_code: 'AUD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/au-13873c845dd7bb5655a50d96e393b1e5c08af1bd431f14a91d1d616cc46f192c.svg',
  },
  CX: {
    currency: 'Australian Dollars',
    country: 'Christmas Island',
    currency_code: 'AUD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/cx-b88b54564b2548cf3372a7d5765c326807c5f12bff8188847f1e9cf0eff9f4ce.svg',
  },
  CC: {
    currency: 'Australian Dollars',
    country: 'Cocos (Keeling) Islands',
    currency_code: 'AUD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/cc-5a2493732775d48804c70cd39578c71af089cc4c4ce84c995a9d0a0f52ad0ba2.svg',
  },
  HM: {
    currency: 'Australian Dollars',
    country: 'Heard and Mc Donald Islands',
    currency_code: 'AUD',
  },
  KI: {
    currency: 'Australian Dollars',
    country: 'Kiribati',
    currency_code: 'AUD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/ki-27438e76ea03d1a7ef2d083b8f15de1c7459db5089d28812bacd8ac627028d94.svg',
  },
  NR: {
    currency: 'Australian Dollars',
    country: 'Nauru',
    currency_code: 'AUD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/nr-e508ea0235490f99c9324cc8fd18d4d6880b4eb7c31dfda6bd355f6589e4045b.svg',
  },
  NF: {
    currency: 'Australian Dollars',
    country: 'Norfolk Island',
    currency_code: 'AUD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/nf-d73d4a2d8abc36919f3710b84e623ad689f3ce7db90ef418e507754240c85cab.svg',
  },
  TV: {
    currency: 'Australian Dollars',
    country: 'Tuvalu',
    currency_code: 'AUD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/tv-6c3d868cb703f91a90b338a6b6c4f7aab35314815e1841655edf4f2d805bf014.svg',
  },
  AS: {
    currency: 'Euros',
    country: 'American Samoa',
    currency_code: 'EUR',
    currency_symbol: '$',
    flag: 'https://flagcdn.com/as.svg',
  },
  AD: {
    currency: 'Euros',
    country: 'Andorra',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/ad-2d3299fc054096c47ab0c45f9d10614bb0d939ccc4d1c688078c7b1bfbec2a01.svg',
  },
  AT: {
    currency: 'Euros',
    country: 'Austria',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/at-dba77f84783603578980c0ae9801d617e27ff9acca6c45be81264a6b1d7df8b6.svg',
  },
  BE: {
    currency: 'Euros',
    country: 'Belgium',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/be-806dd1aa913ace4035ededeac4c1708e323909c7bf213b6b3e4245109aa8ba02.svg',
  },
  FI: {
    currency: 'Euros',
    country: 'Finland',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/fi-8c4f1b318e181809c96b510c9d4c27b0b0146e9e01b2a0914c5bf374d11e2886.svg',
  },
  FR: {
    currency: 'Euros',
    country: 'France',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/fr-10d09cbc2a8b2219da141bbacc940ec8f45a55b36b0dd8f92d07c6c93331f85f.svg',
  },
  GF: {
    currency: 'Euros',
    country: 'French Guiana',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/gf-e5758afce89dedfa86013c413f6b1770f6b6f29bbdfcf8c9ec67c0727a8dbebe.svg',
  },
  TF: {
    currency: 'Euros',
    country: 'French Southern Territories',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: 'https://flagcdn.com/tf.svg',
  },
  DE: {
    currency: 'Euros',
    country: 'Germany',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/de-cb66769cd2af2a363f35a0f1da6c7a5c584c35a6391f2dd4878c6f9005f3ecb6.svg',
  },
  GR: {
    currency: 'Euros',
    country: 'Greece',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/gr-8df55bbaf97f7ec255c4edd00172fa549fda7c6b0f805a3f5bfda56244e4064d.svg',
  },
  GP: {
    currency: 'Euros',
    country: 'Guadeloupe',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/gp-10d09cbc2a8b2219da141bbacc940ec8f45a55b36b0dd8f92d07c6c93331f85f.svg',
  },
  IE: {
    currency: 'Euros',
    country: 'Ireland',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/ie-8147f9c27f0e03a7873cd3183607439337bc9b49b8d2578c3819df5cd76bf02e.svg',
  },
  IT: {
    currency: 'Euros',
    country: 'Italy',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/it-df6faa9601d03e30d30e9a0e630d0286bcdc4306a0570f217034077e7f5875f8.svg',
  },
  LU: {
    currency: 'Euros',
    country: 'Luxembourg',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/lu-a71df8b19ddbb2961172c98762fd06c1046b3cad36b63dd45436a2896b0067cf.svg',
  },
  MQ: {
    currency: 'Euros',
    country: 'Martinique',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/mq-29cf42ac8ce58f180821b06aba8015ecd77bcd8a84d7e6f5c4cba71410662ecb.svg',
  },
  YT: {
    currency: 'Euros',
    country: 'Mayotte',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/yt-53a8a7d8c53d71036c24aebb45e0192f98cad78b4ea3cfdeb695bd2f9bf2b7f5.svg',
  },
  MC: {
    currency: 'Euros',
    country: 'Monaco',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/mc-c11b3585a056936dd491686fd8d89e6b07488a803348fc5875d64d2a89b8ff05.svg',
  },
  NL: {
    currency: 'Euros',
    country: 'Netherlands',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/nl-1e00d26bd585c1484559fa8676f9b81bce9578ae149b454921f60392a106a328.svg',
  },
  PT: {
    currency: 'Euros',
    country: 'Portugal',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/pt-6607776592531b9043b8733f61f360acee1731a1ae6b797583ec6f4b3b2843dc.svg',
  },
  RE: {
    currency: 'Euros',
    country: 'Reunion',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/re-53a8a7d8c53d71036c24aebb45e0192f98cad78b4ea3cfdeb695bd2f9bf2b7f5.svg',
  },
  WS: {
    currency: 'Euros',
    country: 'Samoa',
    currency_code: 'EUR',
    currency_symbol: 'T',
    flag: '//cdn.shopify.com/s/assets/flags/ws-ed786f3d202d03dc7b6e6231733f2bbf617e7555aacc7013af7fd7d50299ff74.svg',
  },
  SM: {
    currency: 'Euros',
    country: 'San Marino',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/sm-b645203f31e0e6dfd5e43e79d6acf49a95ee8db79d400dd2c701434d1b86e453.svg',
  },
  SI: {
    currency: 'Euros',
    country: 'Slovenia',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/si-8febfd67858cd45d6bd4c044688021b5bdc4ccbe75decd4d34f0415f9a8c5322.svg',
  },
  ES: {
    currency: 'Euros',
    country: 'Spain',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/es-65865b592449f3b8d056283310c88f3bbfc439a23ff1df1a25c811b07a54f1e8.svg',
  },
  VA: {
    currency: 'Euros',
    country: 'Vatican City State (Holy See)',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/va-5fb5774b8de2849de4208dceaeef0f1705f4dbd5de11c024285f3cb174c47c65.svg',
  },
  GS: {
    currency: 'Sterling',
    country: 'South Georgia and the South Sandwich Islands',
    currency_code: 'GBP',
    currency_symbol: '£',
    flag: '//cdn.shopify.com/s/assets/flags/gs-cd2b98530322bea8861c74fcfe2d292454ecdb905ec2622387e48bf7d43346b2.svg',
  },
  GB: {
    currency: 'Sterling',
    country: 'United Kingdom',
    currency_code: 'GBP',
    currency_symbol: '£',
    flag: '//cdn.shopify.com/s/assets/flags/gb-de46013c87c1d6b0e4804a6bd8be50f2c5f961c5adbc42ba40b8913af33afe75.svg',
  },
  JE: {
    currency: 'USD',
    country: 'Jersey',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/je-ed7839ff4b12fae6c676427feb0a2ca599a9f66bd97f887a5ab84443af5a38b9.svg',
  },
  IO: {
    currency: 'USD',
    country: 'British Indian Ocean Territory',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/io-67bf3cf21d1e1e4c1c5245deb86cfb21d3791bd63f6a33a2adfd8d7f6c9d90a0.svg',
  },
  GU: {
    currency: 'USD',
    country: 'Guam',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: 'https://flagcdn.com/gu.svg',
  },
  MH: {
    currency: 'USD',
    country: 'Marshall Islands',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: 'https://flagcdn.com/mh.svg',
  },
  FM: {
    currency: 'USD',
    country: 'Micronesia Federated States of',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: 'https://flagcdn.com/fm.svg',
  },
  MP: {
    currency: 'USD',
    country: 'Northern Mariana Islands',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: 'https://flagcdn.com/mp.svg',
  },
  PW: {
    currency: 'USD',
    country: 'Palau',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: 'https://flagcdn.com/pw.svg',
  },
  PR: {
    currency: 'USD',
    country: 'Puerto Rico',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: 'https://flagcdn.com/pr.svg',
  },
  TC: {
    currency: 'USD',
    country: 'Turks and Caicos Islands',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/tc-2e92bdaae7481ce4fa86a7252290875e0c552c13a57b1b818278b10e38f796b4.svg',
  },
  US: {
    currency: 'USD',
    country: 'United States',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/us-1c64c14fa68916dba409ddf0e38ca5dc8bd262b959a5814ecb6667096b35efa5.svg',
  },
  UM: {
    currency: 'USD',
    country: 'United States Minor Outlying Islands',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: 'https://flagcdn.com/um.svg',
  },
  VG: {
    currency: 'USD',
    country: 'Virgin Islands (British)',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/vg-c90981b23d2d3856297e5aa445ac5046ef0f03d7ff4242324c327abe5ab79a81.svg',
  },
  VI: {
    currency: 'USD',
    country: 'Virgin Islands (US)',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: 'https://flagcdn.com/vi.svg',
  },
  HK: {
    currency: 'Hong Kong dollar',
    country: 'Hong Kong',
    currency_code: 'HKD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/hk-6eedf73300bf96c4ffb2528905eb62da02456b532d08545de303aa55cd0cf057.svg',
  },
  CA: {
    currency: 'Canadian Dollar',
    country: 'Canada',
    currency_code: 'CAD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/ca-0d78c57d795e496c1419de30c2af44b0d9b3cb96299cf879dcbf08cf9bbf41a4.svg',
  },
  JP: {
    currency: 'Japanese Yen',
    country: 'Japan',
    currency_code: 'JPY',
    currency_symbol: '¥',
    flag: '//cdn.shopify.com/s/assets/flags/jp-51834ceb282af719ada5d7477c503a77711d1e57d0b0a5665d78497e6c9521c8.svg',
  },
  AF: {
    currency: 'Afghani',
    country: 'Afghanistan',
    currency_code: 'AFN',
    currency_symbol: '؋',
    flag: '//cdn.shopify.com/s/assets/flags/af-eb8e279df9906ed3fd391df59a7a475f754748edaa733071b57c0a641b8d24fd.svg',
  },
  AL: {
    currency: 'Lek',
    country: 'Albania',
    currency_code: 'ALL',
    currency_symbol: 'L',
    flag: '//cdn.shopify.com/s/assets/flags/al-77334b551675f1722f013e89984e0650736057dfa3969e70fd268ff9d61886bb.svg',
  },
  DZ: {
    currency: 'Algerian Dinar',
    country: 'Algeria',
    currency_code: 'DZD',
    currency_symbol: 'د.ج',
    flag: '//cdn.shopify.com/s/assets/flags/dz-9812959bac8cecc27f421e0651c970978d33b90f2b9e3ec6ba189331708e906f.svg',
  },
  AI: {
    currency: 'East Caribbean Dollar',
    country: 'Anguilla',
    currency_code: 'XCD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/ai-7b0902b0d75c927173f2768307ed089e9b5a991c62d841fb51063f8aa1ed65a6.svg',
  },
  AG: {
    currency: 'East Caribbean Dollar',
    country: 'Antigua and Barbuda',
    currency_code: 'XCD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/ag-b912d2a04490401a66441bdc4a702fdd0de83098dbc81e82dd7ba249d801d46a.svg',
  },
  DM: {
    currency: 'East Caribbean Dollar',
    country: 'Dominica',
    currency_code: 'XCD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/dm-c9dc4a0b36fe5c2c0f18a2d13d6f44239fd9f495cd89558cac8645393a64f5b1.svg',
  },
  GD: {
    currency: 'East Caribbean Dollar',
    country: 'Grenada',
    currency_code: 'XCD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/gd-b00fe4a1299babda4b4926408d6335e9de55ee2a43906a80ca5092a2f7bba53b.svg',
  },
  MS: {
    currency: 'East Caribbean Dollar',
    country: 'Montserrat',
    currency_code: 'XCD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/ms-e4bf8dedd7c10ae7ceff1bac1af7c79ad9e86a2dad24407f8e913ee35585f4d8.svg',
  },
  KN: {
    currency: 'East Caribbean Dollar',
    country: 'Saint Kitts',
    currency_code: 'XCD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/kn-0694b6730f80c2ec3f9ca57a83238f58185dd35b1172a2d3880820fd0d0b204d.svg',
  },
  LC: {
    currency: 'East Caribbean Dollar',
    country: 'Saint Lucia',
    currency_code: 'XCD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/lc-bc26d1a97a4c2c573e0410dc094fd332dc750579895013c640bed592ab2d768c.svg',
  },
  VC: {
    currency: 'East Caribbean Dollar',
    country: 'Saint Vincent Grenadines',
    currency_code: 'XCD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/vc-56c6993a134c703097809cafaace9c453980f968219454d41487644bfc865256.svg',
  },
  AR: {
    currency: 'Peso',
    country: 'Argentina',
    currency_code: 'ARS',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/ar-dc0a5ed2f626c59955a9ac31509fb91cced47ccc437dfae53d60a38005249afc.svg',
  },
  AM: {
    currency: 'Dram',
    country: 'Armenia',
    currency_code: 'AMD',
    currency_symbol: '֏',
    flag: '//cdn.shopify.com/s/assets/flags/am-a9a3a54409e1d2bef4ea0c53a2b66dc7432254fd1580326d0e5a9fb7b18f5dac.svg',
  },
  AW: {
    currency: 'Netherlands Antilles Guilder',
    country: 'Aruba',
    currency_code: 'ANG',
    currency_symbol: 'ƒ',
    flag: '//cdn.shopify.com/s/assets/flags/aw-3728e43855e00765ef2e40f197946649fde06f07a0fb33a124dbbdd94b793e25.svg',
  },
  AN: {
    currency: 'Netherlands Antilles Guilder',
    country: 'Netherlands Antilles',
    currency_code: 'ANG',
    flag: '//cdn.shopify.com/s/assets/flags/an-674c77b804f2adf1488993b5a8cbbb0501da5fcc8a8b88a3835f3338a1865ecc.svg',
  },
  AZ: {
    currency: 'Manat',
    country: 'Azerbaijan',
    currency_code: 'AZN',
    currency_symbol: '₼',
    flag: '//cdn.shopify.com/s/assets/flags/az-1085ba921a81e661397f00206ace09ae5719643cabee59ffa0674cfee62f2691.svg',
  },
  BS: {
    currency: 'Bahamian Dollar',
    country: 'Bahamas',
    currency_code: 'BSD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/bs-bcc952e638d0e3de6f5e595b461b9629e6291330e73a81e857bb8645978dbb81.svg',
  },
  BH: {
    currency: 'Bahraini Dinar',
    country: 'Bahrain',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/bh-0ca8149dfcdd96887846d04bfecd9f27e700ad5f7118a5385db4ba2cc3f16893.svg',
  },
  BD: {
    currency: 'Taka',
    country: 'Bangladesh',
    currency_code: 'BDT',
    currency_symbol: '৳',
    flag: '//cdn.shopify.com/s/assets/flags/bd-e191b65efc29531da2809f91b04414a5487be88ebaaff3615b7a32da8c3d6c4e.svg',
  },
  BB: {
    currency: 'Barbadian Dollar',
    country: 'Barbados',
    currency_code: 'BBD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/bb-ddc91bc3a20cfc3ea3a5618caf4bfc400aa6b25e131fb191f3c65e2cccd0def4.svg',
  },
  BY: {
    currency: 'Belarus Ruble',
    country: 'Belarus',
    currency_code: 'BYR',
    currency_symbol: 'Br',
    flag: '//cdn.shopify.com/s/assets/flags/by-bdf871401d4ab7ef2781e80954d67d108f7756b0d89ce2acfc216d4ae2c20d6b.svg',
  },
  BZ: {
    currency: 'Belizean Dollar',
    country: 'Belize',
    currency_code: 'BZD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/bz-00a3d96a2ac76b7edaedcbc5c654b620e332c16774aac64fb00e290135dadec3.svg',
  },
  BJ: {
    currency: 'CFA Franc BCEAO',
    country: 'Benin',
    currency_code: 'XOF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/bj-6a2811523006a49b45b675777d43dba45e925136493ee3a03391a56a97fe9b8e.svg',
  },
  BF: {
    currency: 'CFA Franc BCEAO',
    country: 'Burkina Faso',
    currency_code: 'XOF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/bf-66c389db1137ee09851de2e89d0b103f7917b5f055da530b23460eee7dac0e47.svg',
  },
  GW: {
    currency: 'CFA Franc BCEAO',
    country: 'Guinea-Bissau',
    currency_code: 'XOF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/gw-68a5ffd842fed63a8ffcd151180b4b19155ab7cd13987cd3a31d86f6ac51763a.svg',
  },
  CI: {
    currency: 'CFA Franc BCEAO',
    country: 'Ivory Coast',
    currency_code: 'XOF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/ci-03c70c3f8d18a25facb7c9db9704b9318bd388453bd3b5fc68e8e11a708e978f.svg',
  },
  ML: {
    currency: 'CFA Franc BCEAO',
    country: 'Mali',
    currency_code: 'XOF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/ml-ceed9317fbbb708971d76b1f0f5979130ce44f7c99b829dd226c777094c135e5.svg',
  },
  NE: {
    currency: 'CFA Franc BCEAO',
    country: 'Niger',
    currency_code: 'XOF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/ne-36d7acf7411e112f62f261fd0ada6e4fe2852220f0725a0b9bd241c6427ba102.svg',
  },
  SN: {
    currency: 'CFA Franc BCEAO',
    country: 'Senegal',
    currency_code: 'XOF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/sn-0fb14fcca74b1a937d3a0cc8212cd1aff8e7ea84d57d2e5531e77aea5eb8e5ca.svg',
  },
  TG: {
    currency: 'CFA Franc BCEAO',
    country: 'Togo',
    currency_code: 'XOF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/tg-3d32336a265064b50f45029e913cca70087811aca9b4043d94c2aa6909ddf403.svg',
  },
  BM: {
    currency: 'Bermudian Dollar',
    country: 'Bermuda',
    currency_code: 'BMD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/bm-8de9be055ea3598b4125e7cc07f6582582649174f35f2a5c43190bd1038f7dea.svg',
  },
  BT: {
    currency: 'Indian Rupee',
    country: 'Bhutan',
    currency_code: 'INR',
    currency_symbol: 'Nu.',
    flag: '//cdn.shopify.com/s/assets/flags/bt-2268a30a79e240aff419159cdd73e7b2098b36bcffbfd69ee03a17efaa8221be.svg',
  },
  IN: {
    currency: 'Indian Rupee',
    country: 'India',
    currency_code: 'INR',
    currency_symbol: '₹',
    flag: '//cdn.shopify.com/s/assets/flags/in-0e84df729f1dd51112bd51e4523e7f5416aa65ade27b7f66056bcb13e1f933c4.svg',
  },
  BO: {
    currency: 'Boliviano',
    country: 'Bolivia',
    currency_code: 'BOB',
    currency_symbol: 'Bs.',
    flag: '//cdn.shopify.com/s/assets/flags/bo-cfd48d23d6bfdb99c5d89fc035ebceae2e331b4802fbe531a38d7c6bf12772b9.svg',
  },
  BW: {
    currency: 'Pula',
    country: 'Botswana',
    currency_code: 'BWP',
    currency_symbol: 'P',
    flag: '//cdn.shopify.com/s/assets/flags/bw-be023689fbd86bd7d217a975913a57418ea617c6d0c525078f556d3276f5b2a7.svg',
  },
  BV: {
    currency: 'Norwegian Krone',
    country: 'Bouvet Island',
    currency_code: 'NOK',
  },
  NO: {
    currency: 'Norwegian Krone',
    country: 'Norway',
    currency_code: 'NOK',
    currency_symbol: 'kr',
    flag: '//cdn.shopify.com/s/assets/flags/no-037c0f21f9ad53589902ddc411e836a94298641b2870ceba0bfac9ffee62352e.svg',
  },
  SJ: {
    currency: 'Norwegian Krone',
    country: 'Svalbard and Jan Mayen Islands',
    currency_code: 'NOK',
    currency_symbol: 'kr',
    flag: '//cdn.shopify.com/s/assets/flags/sj-0cc92b26396ffd50b4c4e7aaafc1e1bbdf362cb56776721cf33053b936edd1da.svg',
  },
  BR: {
    currency: 'Brazil',
    country: 'Brazil',
    currency_code: 'BRL',
    currency_symbol: 'R$',
    flag: '//cdn.shopify.com/s/assets/flags/br-c0141f79405d80a7da70de1b45f6f9ce24e52f65c6c7b4dfa2e4b4728aa02660.svg',
  },
  BN: {
    currency: 'Bruneian Dollar',
    country: 'Brunei Darussalam',
    currency_code: 'BND',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/bn-554e32337da87f4f9bfded90be19893b36cc5d55361847836eb5d7d7db0ec468.svg',
  },
  BG: {
    currency: 'Lev',
    country: 'Bulgaria',
    currency_code: 'BGN',
    currency_symbol: 'лв',
    flag: '//cdn.shopify.com/s/assets/flags/bg-bbbd0544dea156ec45b7713db5e66f0efc768482f5a61ddf0cdc9a928f194661.svg',
  },
  BI: {
    currency: 'Burundi Franc',
    country: 'Burundi',
    currency_code: 'BIF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/bi-78effa77b600a9a6f9b24bbffbc36b873250ad5af579b69925a81ca20840ac4b.svg',
  },
  KH: {
    currency: 'Riel',
    country: 'Cambodia',
    currency_code: 'KHR',
    currency_symbol: '៛',
    flag: '//cdn.shopify.com/s/assets/flags/kh-35c33da07e85eddd75f6fb715d54e8524be755b7b3dfa9fc20176c0b291f8022.svg',
  },
  CM: {
    currency: 'CFA Franc BEAC',
    country: 'Cameroon',
    currency_code: 'XAF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/cm-f9fdc4bee29cf5536258eeeb8ad6be79b014a96724a24faa7edb3d5b240cc682.svg',
  },
  CF: {
    currency: 'CFA Franc BEAC',
    country: 'Central African Republic',
    currency_code: 'XAF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/cf-31abdd130dde03f612d658ff986e274b7aa7cea1cd24f287b24b363783ef3b10.svg',
  },
  TD: {
    currency: 'CFA Franc BEAC',
    country: 'Chad',
    currency_code: 'XAF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/td-cf60b0660fef033f1e1c42303e90cd49c31595c755a9885499a0610b55cf32db.svg',
  },
  CG: {
    currency: 'CFA Franc BEAC',
    country: 'Congo Republic of the Democratic',
    currency_code: 'XAF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/cg-2b740c3fdc86df76a4f4ead09ccc6664e0163137207bbb61e7b57e0d4c9be547.svg',
  },
  GQ: {
    currency: 'CFA Franc BEAC',
    country: 'Equatorial Guinea',
    currency_code: 'XAF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/gq-9c100a177dc61bad19c2a062b125adcf18666ac150313b441ed1e3718d0e47ae.svg',
  },
  GA: {
    currency: 'CFA Franc BEAC',
    country: 'Gabon',
    currency_code: 'XAF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/ga-4c7cdf1af94111f99e53c48e3af3326180a9a03b71d032f683bfdd74d116a066.svg',
  },
  CV: {
    currency: 'Escudo',
    country: 'Cape Verde',
    currency_code: 'CVE',
    currency_symbol: 'Esc',
    flag: '//cdn.shopify.com/s/assets/flags/cv-d0389eb5253d58f3b9135acf4690bd8112fcb64d943e1d96d37d49ad79d85329.svg',
  },
  KY: {
    currency: 'Caymanian Dollar',
    country: 'Cayman Islands',
    currency_code: 'KYD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/ky-ed26b486d95eccb95f93b4ef6e052ce1a84c14a08c270909440f444def3a13d2.svg',
  },
  CL: {
    currency: 'Chilean Peso',
    country: 'Chile',
    currency_code: 'CLP',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/cl-dce51159690a7a5026eec5515d46b167d5d025913a66adf0171ed3981c5bd1c1.svg',
  },
  CN: {
    currency: 'Yuan Renminbi',
    country: 'China',
    currency_code: 'CNY',
    currency_symbol: '¥',
    flag: '//cdn.shopify.com/s/assets/flags/cn-eeca693e090f228d7c7f03dc549e93bf6075a39ee70e4788f13d471c421edffd.svg',
  },
  CO: {
    currency: 'Peso',
    country: 'Colombia',
    currency_code: 'COP',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/co-a37a6bc1fe59000adbe09ea8ac216e2b66ba728742e119349bab34d1fd1b3c11.svg',
  },
  KM: {
    currency: 'Comoran Franc',
    country: 'Comoros',
    currency_code: 'KMF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/km-2976c3376cf684fa5901f144705e758b85a218ac83842b9b1db49cba5be381a0.svg',
  },
  CD: {
    currency: 'Congolese Frank',
    country: 'Congo (Kinshasa)',
    currency_code: 'CDF',
    currency_symbol: 'FC',
    flag: '//cdn.shopify.com/s/assets/flags/cd-ae468a43e22f29834d1cf7ec146de566208cd4c080b7e41f89da062869286bb2.svg',
  },
  CR: {
    currency: 'Costa Rican Colon',
    country: 'Costa Rica',
    currency_code: 'CRC',
    currency_symbol: '₡',
    flag: '//cdn.shopify.com/s/assets/flags/cr-7f230d3c663b681efbe66f7c17cf462b8e4654df5b2dc9ed2d4c042481bd6303.svg',
  },
  HR: {
    currency: 'Euros',
    country: 'Croatia (Hrvatska)',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/hr-b0f2f2fb13f5432879442bcae39e125a360f552dd02fe58c9a48fa44a4a9afca.svg',
  },
  CU: {
    currency: 'Cuban Peso',
    country: 'Cuba',
    currency_code: 'CUP',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/cu-aa4bcfbbe8e82a584fe8350cf0d88f69466686babcb4576dbf93b64be9537d07.svg',
  },
  CY: {
    currency: 'Euros',
    country: 'Cyprus',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/cy-2fc766b0049ba792171dd5c20731609ab879a457a0657167d3a9827a2189bd4e.svg',
  },
  CZ: {
    currency: 'Koruna',
    country: 'Czech Republic',
    currency_code: 'CZK',
    currency_symbol: 'Kč',
    flag: '//cdn.shopify.com/s/assets/flags/cz-c7cf8a8de7e5f6bc9ae2f80f1d99272031c8ea2841ca79de86476ef9ae4a901b.svg',
  },
  DK: {
    currency: 'Danish Krone',
    country: 'Denmark',
    currency_code: 'DKK',
    currency_symbol: 'kr',
    flag: '//cdn.shopify.com/s/assets/flags/dk-d15b90eddd91956b7de55019da0479b09a3342d998f38acd5847d3c0be528eaa.svg',
  },
  FO: {
    currency: 'Danish Krone',
    country: 'Faroe Islands',
    currency_code: 'DKK',
    currency_symbol: 'kr',
    flag: '//cdn.shopify.com/s/assets/flags/fo-ba7ea8e10960ce78eb1a627faba52b8efcdd937bcf83acfb35f54635d79639c3.svg',
  },
  GL: {
    currency: 'Danish Krone',
    country: 'Greenland',
    currency_code: 'DKK',
    currency_symbol: 'kr.',
    flag: '//cdn.shopify.com/s/assets/flags/gl-95144c3e191cd567fee82bf0f223f4f5729a2b7a9d9115ab713411c61fb59a12.svg',
  },
  DJ: {
    currency: 'Djiboutian Franc',
    country: 'Djibouti',
    currency_code: 'DJF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/dj-9fdbecaccd3869bac5684ab654f4d2adfad0cb8670b593d59792c1e08db9c8e2.svg',
  },
  DO: {
    currency: 'Dominican Peso',
    country: 'Dominican Republic',
    currency_code: 'DOP',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/do-cac884ee8edbc3a89dcae1494b2bdef504ab78c590f7075cc7dbb5fb1c2b6cf6.svg',
  },
  TP: {
    currency: 'Indonesian Rupiah',
    country: 'East Timor',
    currency_code: 'IDR',
  },
  ID: {
    currency: 'Indonesian Rupiah',
    country: 'Indonesia',
    currency_code: 'IDR',
    currency_symbol: 'Rp',
    flag: '//cdn.shopify.com/s/assets/flags/id-af8ae4bcec649b476a512871baf313544722c3dc3b9336e6bc15496e1e158cfb.svg',
  },
  EC: {
    currency: 'Sucre',
    country: 'Ecuador',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/ec-c596bab16fe217175364df9f15750a6feccab34afdaa8c8c423e3094e06c62a3.svg',
  },
  EG: {
    currency: 'Egyptian Pound',
    country: 'Egypt',
    currency_code: 'EGP',
    currency_symbol: '£',
    flag: '//cdn.shopify.com/s/assets/flags/eg-5b2eda03efba7ebfb1c548ca444b4b406b8f80d1b1e68935de595f7ef9b1a5af.svg',
  },
  SV: {
    currency: 'Salvadoran Colon',
    country: 'El Salvador',
    currency_code: 'SVC',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/sv-006db52e2d7a1c487539ae6c1e78e3155566ee2f7929c1e03223e6b737ac6ab9.svg',
  },
  ER: {
    currency: 'Ethiopian Birr',
    country: 'Eritrea',
    currency_code: 'ETB',
    currency_symbol: 'Nfk',
    flag: '//cdn.shopify.com/s/assets/flags/er-b6b91d43ee051cc4d28acdabdeede03d0459fae3b5c58a0cece76ff97ebf6a0b.svg',
  },
  ET: {
    currency: 'Ethiopian Birr',
    country: 'Ethiopia',
    currency_code: 'ETB',
    currency_symbol: 'Br',
    flag: '//cdn.shopify.com/s/assets/flags/et-88bc1ebc97fb568afd75c3d34ce56c3c689134d8c3bdd42c4bcd1c33ded0e622.svg',
  },
  EE: {
    currency: 'Euros',
    country: 'Estonia',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/ee-cf276fa448f6bc2db954c646d66ad95094b3196c1ba3eb4c2cf4de9a5fec8440.svg',
  },
  FK: {
    currency: 'Falkland Pound',
    country: 'Falkland Islands (Malvinas)',
    currency_code: 'FKP',
    currency_symbol: '£',
    flag: '//cdn.shopify.com/s/assets/flags/fk-b035f39f06bfca7c5ddb552910884b207c348675be064d1a9acb38a09fd46537.svg',
  },
  FJ: {
    currency: 'Fijian Dollar',
    country: 'Fiji',
    currency_code: 'FJD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/fj-95a085e87c958f72664e1048cc0cf71b3a4e29bfccd6f3a8186f955c3766ca13.svg',
  },
  PF: {
    currency: 'CFP Franc',
    country: 'French Polynesia',
    currency_code: 'XPF',
    currency_symbol: '₣',
    flag: '//cdn.shopify.com/s/assets/flags/pf-5aeb49aac473f8e2718d7f668538451a52b6040c0f2b5c49dc0459e098ae6dbd.svg',
  },
  NC: {
    currency: 'CFP Franc',
    country: 'New Caledonia',
    currency_code: 'XPF',
    currency_symbol: '₣',
    flag: '//cdn.shopify.com/s/assets/flags/nc-53a8a7d8c53d71036c24aebb45e0192f98cad78b4ea3cfdeb695bd2f9bf2b7f5.svg',
  },
  WF: {
    currency: 'CFP Franc',
    country: 'Wallis and Futuna Islands',
    currency_code: 'XPF',
    currency_symbol: '₣',
    flag: '//cdn.shopify.com/s/assets/flags/wf-10d09cbc2a8b2219da141bbacc940ec8f45a55b36b0dd8f92d07c6c93331f85f.svg',
  },
  GM: {
    currency: 'Dalasi',
    country: 'Gambia',
    currency_code: 'GMD',
    currency_symbol: 'D',
    flag: '//cdn.shopify.com/s/assets/flags/gm-242e323d8c81ae460bd10e89d45d718b995451c6ab77f3833ae7248cbca76e0e.svg',
  },
  GE: {
    currency: 'Lari',
    country: 'Georgia',
    currency_code: 'GEL',
    currency_symbol: '₾',
    flag: '//cdn.shopify.com/s/assets/flags/ge-7abbfeb26a91671f1e6f6f1ac9bbe7ff98315929241ae5aae4d891244c4fc1d9.svg',
  },
  GI: {
    currency: 'Gibraltar Pound',
    country: 'Gibraltar',
    currency_code: 'GIP',
    currency_symbol: '£',
    flag: '//cdn.shopify.com/s/assets/flags/gi-fcca3661db81ea804e6e295390699f9b12381cdeb9e89e6452844a9f47326b23.svg',
  },
  GT: {
    currency: 'Quetzal',
    country: 'Guatemala',
    currency_code: 'GTQ',
    currency_symbol: 'Q',
    flag: '//cdn.shopify.com/s/assets/flags/gt-6b7c52001bc25b596e05cc2730f93d6976271bd7fda1667a0a6171573a5eb0cf.svg',
  },
  GN: {
    currency: 'Guinean Franc',
    country: 'Guinea',
    currency_code: 'GNF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/gn-52a9dc98e6122cd57cc813c4da100240ab7814f724a66b0954a0c34c145a4ddf.svg',
  },
  GY: {
    currency: 'Guyanaese Dollar',
    country: 'Guyana',
    currency_code: 'GYD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/gy-da90cfa08127e416de3191deecfc4347da6431b60583c685e424aba6be5681e9.svg',
  },
  HT: {
    currency: 'Gourde',
    country: 'Haiti',
    currency_code: 'HTG',
    currency_symbol: 'G',
    flag: '//cdn.shopify.com/s/assets/flags/ht-ce3ae14b69acfda0e166b359341be5344b484d47531a517247f3871277aaee48.svg',
  },
  HN: {
    currency: 'Lempira',
    country: 'Honduras',
    currency_code: 'HNL',
    currency_symbol: 'L',
    flag: '//cdn.shopify.com/s/assets/flags/hn-3c0b0aaff2299cdcb42f8c8d39aec9c24831332914032cedb89f09d52d2dc0a6.svg',
  },
  HU: {
    currency: 'Forint',
    country: 'Hungary',
    currency_code: 'HUF',
    currency_symbol: 'Ft',
    flag: '//cdn.shopify.com/s/assets/flags/hu-5b54a899b7a3fd5dfbd4dbe9ed320af2c58162267c9c16cf89ae1fa92d9bb38f.svg',
  },
  IS: {
    currency: 'Icelandic Krona',
    country: 'Iceland',
    currency_code: 'ISK',
    currency_symbol: 'kr',
    flag: '//cdn.shopify.com/s/assets/flags/is-7d6167f029623e57ee7b030708b18ae267ff961a4f829b37cbc246a0c9311474.svg',
  },
  IR: {
    currency: 'Iranian Rial',
    country: 'Iran (Islamic Republic of)',
    currency_code: 'IRR',
    currency_symbol: '﷼',
    flag: '//cdn.shopify.com/s/assets/flags/ir-680330c54ee3ba2f3528855ea02611ed16adcc63cf7f98cb5e8f2f10cb7a5743.svg',
  },
  IQ: {
    currency: 'Iraqi Dinar',
    country: 'Iraq',
    currency_code: 'IQD',
    currency_symbol: 'ع.د',
    flag: '//cdn.shopify.com/s/assets/flags/iq-2fc377138af11be17c6f5e45da8dd971b237fab2bc7f273a01d5609796b66369.svg',
  },
  IL: {
    currency: 'Shekel',
    country: 'Israel',
    currency_code: 'ILS',
    currency_symbol: '₪',
    flag: '//cdn.shopify.com/s/assets/flags/il-e2f8bc4dab5fcdf4901092f3e50d733859dbf9afc960b2ff159ef4042f58b39c.svg',
  },
  JM: {
    currency: 'Jamaican Dollar',
    country: 'Jamaica',
    currency_code: 'JMD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/jm-0e3edd42bd4bf86be415d5dc3689bdcb816b00eab77d0b56552091b85e775114.svg',
  },
  JO: {
    currency: 'Jordanian Dinar',
    country: 'Jordan',
    currency_code: 'JOD',
    currency_symbol: 'د.ا',
    flag: '//cdn.shopify.com/s/assets/flags/jo-dff53aefd172f5a6d6e325609fdb4a07d83e0debd66e5c38352f49ad1bd8160c.svg',
  },
  KZ: {
    currency: 'Tenge',
    country: 'Kazakhstan',
    currency_code: 'KZT',
    currency_symbol: '₸',
    flag: '//cdn.shopify.com/s/assets/flags/kz-b5f40935a6b8bd057a0ede54b93916f4707a5c90ffe0199397bb4f5664a35876.svg',
  },
  KE: {
    currency: 'Kenyan Shilling',
    country: 'Kenya',
    currency_code: 'KES',
    currency_symbol: 'Sh',
    flag: '//cdn.shopify.com/s/assets/flags/ke-edc7377bd7abe9e998476174f05dd510af1eadd5f4d010ebac91df6686b2762e.svg',
  },
  KP: {
    currency: 'Won',
    country: 'Korea North',
    currency_code: 'KPW',
    currency_symbol: '₩',
    flag: '//cdn.shopify.com/s/assets/flags/kp-b28bc1d8a19853619340a9d9cb7031b444b370331b5844c4c43d2960426e9e9c.svg',
  },
  KR: {
    currency: 'Won',
    country: 'Korea South',
    currency_code: 'KRW',
    currency_symbol: '₩',
    flag: '//cdn.shopify.com/s/assets/flags/kr-226f316d7ae7a184e23d015e3982bd9d685ac8071fc2ee19906d0f6031489f19.svg',
  },
  KW: {
    currency: 'USD',
    country: 'Kuwait',
    currency_code: 'USD',
    currency_symbol: "$",
    flag: '//cdn.shopify.com/s/assets/flags/kw-12b68de9c29ff5e79c9e92c8a31f21de9654122dff304fe90c07c797f994dcd8.svg',
  },
  KG: {
    currency: 'Som',
    country: 'Kyrgyzstan',
    currency_code: 'KGS',
    currency_symbol: 'с',
    flag: '//cdn.shopify.com/s/assets/flags/kg-9eec999003db08c23990f0a7f5f7f3cff5cd24161a70fbaff7679b784add8da0.svg',
  },
  LA: {
    currency: 'Lao kip',
    country: 'Laos',
    currency_code: 'LAK',
    currency_symbol: '₭',
    flag: '//cdn.shopify.com/s/assets/flags/la-144b105e19bcb19e4420dc87b12b9d39e03f80d3cf1224061f283d626433c892.svg',
  },
  LV: {
    currency: 'Euros',
    country: 'Latvia',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/lv-b4dedfbd55b8f448602419e83c40ed6e3ac1cc9752c62b7905ac9800cee715af.svg',
  },
  LB: {
    currency: 'Lebanese Pound',
    country: 'Lebanon',
    currency_code: 'LBP',
    currency_symbol: 'ل.ل',
    flag: '//cdn.shopify.com/s/assets/flags/lb-c63097230534fca80f65af98f1465625baf6b4f05a977d19d8833f89bad4557d.svg',
  },
  LS: {
    currency: 'Loti',
    country: 'Lesotho',
    currency_code: 'LSL',
    currency_symbol: 'L',
    flag: '//cdn.shopify.com/s/assets/flags/ls-69b000e98f9ddb08fc8e38d1b2d5217f122265d4cc544930a63b489f4c411a44.svg',
  },
  LR: {
    currency: 'Liberian Dollar',
    country: 'Liberia',
    currency_code: 'LRD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/lr-d0286d2e904808cc882b263cf582c3e6f390582fd9ac8aa450b2e69ebd9bc84d.svg',
  },
  LY: {
    currency: 'Libyan Dinar',
    country: 'Libyan Arab Jamahiriya',
    currency_code: 'LYD',
    currency_symbol: 'ل.د',
    flag: '//cdn.shopify.com/s/assets/flags/ly-3de463fa8f7de627b85cba38c090a0e69d1ae88fcb62b78e162842d84ac4c273.svg',
  },
  LI: {
    currency: 'Swiss Franc',
    country: 'Liechtenstein',
    currency_code: 'CHF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/li-bd0573b7eee6b796f5e1ce27eeebd8176fe19c7e64dfca90071179e8428ebec5.svg',
  },
  CH: {
    currency: 'Swiss Franc',
    country: 'Switzerland',
    currency_code: 'CHF',
    currency_symbol: 'Fr.',
    flag: '//cdn.shopify.com/s/assets/flags/ch-ac08fa33836b67eb7fbcaad7a0244a685a2dd367555f34de139f668a9f6c7885.svg',
  },
  LT: {
    currency: 'Euros',
    country: 'Lithuania',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/lt-7e19f8f51065107f5f9cb2b71e74617a4b158ed990f22a5b66bd1c663454c9f8.svg',
  },
  MO: {
    currency: 'Macanese pataca',
    country: 'Macao S.A.R.',
    currency_code: 'MOP',
    currency_symbol: 'P',
    flag: '//cdn.shopify.com/s/assets/flags/mo-9f244f9cd5f2279bc8ac1f4e5d01f7c0afaff6b64e566346740a102340cc0f35.svg',
  },
  MK: {
    currency: 'Denar',
    country: 'Macedonia',
    currency_code: 'MKD',
    currency_symbol: 'den',
    flag: '//cdn.shopify.com/s/assets/flags/mk-9605dc33ee8848f82ec61c57f7e802f32137e6ca31caa907918f1edc44d9ba47.svg',
  },
  MG: {
    currency: 'Malagasy Franc',
    country: 'Madagascar',
    currency_code: 'MGA',
    currency_symbol: 'Ar',
    flag: '//cdn.shopify.com/s/assets/flags/mg-2b91613e51c09ec0dcd2fa8d3d53cd4140eb1a204676c00cdd5fda1a762363a6.svg',
  },
  MW: {
    currency: 'Malawian Kwacha',
    country: 'Malawi',
    currency_code: 'MWK',
    currency_symbol: 'MK',
    flag: '//cdn.shopify.com/s/assets/flags/mw-e9c9476184afa84a0bde62516e0078ff0594b516cd838119ca7a93d273db81ee.svg',
  },
  MY: {
    currency: 'Ringgit',
    country: 'Malaysia',
    currency_code: 'MYR',
    currency_symbol: 'RM',
    flag: '//cdn.shopify.com/s/assets/flags/my-62b6693d248cda235b57a98a890d31675b9f374ae77d1c6432a80509d9708d5e.svg',
  },
  MV: {
    currency: 'Rufiyaa',
    country: 'Maldives',
    currency_code: 'MVR',
    currency_symbol: '.ރ',
    flag: '//cdn.shopify.com/s/assets/flags/mv-c2eb047ffa827867bd29048b939c3f81a94aad885f738b00504624a254161dd4.svg',
  },
  MT: {
    currency: 'Euros',
    country: 'Malta',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/mt-a5effdb9c54a7168fced0465607d245ab70ba7d19cc72bb47c847178750eead9.svg',
  },
  MR: {
    currency: 'Ouguiya',
    country: 'Mauritania',
    currency_code: 'MRO',
    currency_symbol: 'UM',
    flag: '//cdn.shopify.com/s/assets/flags/mr-7394cb78de686b7b2b308c26afaeeec859ca44135429edaf704aaa4c0f25fad8.svg',
  },
  MU: {
    currency: 'Mauritian Rupee',
    country: 'Mauritius',
    currency_code: 'MUR',
    currency_symbol: '₨',
    flag: '//cdn.shopify.com/s/assets/flags/mu-7554fbc2982cdb1b6537c8e8d87758cb3efa5dfc83c5f7a37e4333e97868cdb6.svg',
  },
  MX: {
    currency: 'Peso',
    country: 'Mexico',
    currency_code: 'MXN',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/mx-7c49b8206aa19f98841540b81f52c02bc28e0058528a8c0b2af4b6178de83f10.svg',
  },
  MD: {
    currency: 'Leu',
    country: 'Moldova Republic of',
    currency_code: 'MDL',
    currency_symbol: 'L',
    flag: '//cdn.shopify.com/s/assets/flags/md-e35529c79c00076bd2150fd41f4b1cba0e999f82d5c43ae955cc578a361faa16.svg',
  },
  MN: {
    currency: 'Tugrik',
    country: 'Mongolia',
    currency_code: 'MNT',
    currency_symbol: '₮',
    flag: '//cdn.shopify.com/s/assets/flags/mn-88753edc378f38487765464cff5fc819e36a65effb6dd1424d45ece6a1647e1d.svg',
  },
  MA: {
    currency: 'Dirham',
    country: 'Morocco',
    currency_code: 'MAD',
    currency_symbol: 'د.م.',
    flag: '//cdn.shopify.com/s/assets/flags/ma-35e1c070d6c6783bf105a3ad485320813e8d45651636d2a83f6fa0f363877abc.svg',
  },
  EH: {
    currency: 'Dirham',
    country: 'Western Sahara',
    currency_code: 'MAD',
    currency_symbol: 'دج',
    flag: 'https://flagcdn.com/eh.svg',
  },
  MZ: {
    currency: 'Metical',
    country: 'Mozambique',
    currency_code: 'MZN',
    currency_symbol: 'MT',
    flag: '//cdn.shopify.com/s/assets/flags/mz-1c38e23f096018a517296eeb728ce615b81fd4609f54d21a5d78a8e047788dd3.svg',
  },
  MM: {
    currency: 'Kyat',
    country: 'Myanmar',
    currency_code: 'MMK',
    currency_symbol: 'Ks',
    flag: '//cdn.shopify.com/s/assets/flags/mm-e02730c89f8713dca8a8f14101887d6188415613803ab3bb29c8514590909985.svg',
  },
  NA: {
    currency: 'Dollar',
    country: 'Namibia',
    currency_code: 'NAD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/na-3cc917f4965db4ec44f11752ccedd8e1b7d5ba4a3a4b9d3aa57c77fbfa09b674.svg',
  },
  NP: {
    currency: 'Nepalese Rupee',
    country: 'Nepal',
    currency_code: 'NPR',
    currency_symbol: '₨',
    flag: '//cdn.shopify.com/s/assets/flags/np-fea701a8d05db31af7d284add0dc81d3dc1d97e7094ae9c6848ee9b5ebeb45d8.svg',
  },
  NI: {
    currency: 'Cordoba Oro',
    country: 'Nicaragua',
    currency_code: 'NIO',
    currency_symbol: 'C$',
    flag: '//cdn.shopify.com/s/assets/flags/ni-c33af5e3c1ebb00ea0fb53466f0ee0b69a808cf4c7f77fe1d5272a1233198cc3.svg',
  },
  NG: {
    currency: 'Naira',
    country: 'Nigeria',
    currency_code: 'NGN',
    currency_symbol: '₦',
    flag: '//cdn.shopify.com/s/assets/flags/ng-57301401e20c7ebd6687c930caa9c10c227b593bd38b15a217c45f3f2c36a166.svg',
  },
  OM: {
    currency: 'USD',
    country: 'Oman',
    currency_code: 'USD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/om-808a57ba4c58867066e263520e9d79f90e0c9d460687ab9a370160fb56e1c049.svg',
  },
  PK: {
    currency: 'Rupee',
    country: 'Pakistan',
    currency_code: 'PKR',
    currency_symbol: '₨',
    flag: '//cdn.shopify.com/s/assets/flags/pk-5d1ad05b80296a094512e6edbd43b27a96bef01c0f9852dd540b56debb61d18a.svg',
  },
  PA: {
    currency: 'Balboa',
    country: 'Panama',
    currency_code: 'PAB',
    currency_symbol: 'B/.',
    flag: '//cdn.shopify.com/s/assets/flags/pa-ceb96947effb2596e74c37fa80a049626b71dee595bf21ba5754ac103ac4c476.svg',
  },
  PG: {
    currency: 'Kina',
    country: 'Papua New Guinea',
    currency_code: 'PGK',
    currency_symbol: 'K',
    flag: '//cdn.shopify.com/s/assets/flags/pg-647ab0c92d7f9f6a629d99658f5d956c8424d50eca562d4d33bf2911aa5c13f4.svg',
  },
  PY: {
    currency: 'Guarani',
    country: 'Paraguay',
    currency_code: 'PYG',
    currency_symbol: '₲',
    flag: '//cdn.shopify.com/s/assets/flags/py-67ce02f858a28ad984018f57b2080ee3b45f62ee9fba1a7ffead412ccbd6c5be.svg',
  },
  PE: {
    currency: 'Nuevo Sol',
    country: 'Peru',
    currency_code: 'PEN',
    currency_symbol: 'S/ ',
    flag: '//cdn.shopify.com/s/assets/flags/pe-0e5601aa50fdf7ab9f060114fd66def0f9b37b04177c5d043bb8fe46bef7f90a.svg',
  },
  PH: {
    currency: 'Peso',
    country: 'Philippines',
    currency_code: 'PHP',
    currency_symbol: '₱',
    flag: '//cdn.shopify.com/s/assets/flags/ph-b4958dc8963871a24b0ca6ddec26c436eeee34c0bf34e8e14f9717c708a2c606.svg',
  },
  PL: {
    currency: 'Zloty',
    country: 'Poland',
    currency_code: 'PLN',
    currency_symbol: 'zł',
    flag: '//cdn.shopify.com/s/assets/flags/pl-ca69810c60aacd2e0d17005df7ea4a2318fafa7385b580e33cdfbdb16fc3e2f1.svg',
  },
  QA: {
    currency: 'Rial',
    country: 'Qatar',
    currency_code: 'QAR',
    currency_symbol: 'ر.ق',
    flag: '//cdn.shopify.com/s/assets/flags/qa-8ee0871f5fa6f32717beeef3b9a58d4e45c34583ee2934ee752b803fa88456b9.svg',
  },
  RO: {
    currency: 'Leu',
    country: 'Romania',
    currency_code: 'RON',
    currency_symbol: 'lei',
    flag: '//cdn.shopify.com/s/assets/flags/ro-15755637dd265e88f60a53a6e62d8daf8ad893dfa4e42f51085b9c778f0d2e91.svg',
  },
  RU: {
    currency: 'Ruble',
    country: 'Russian Federation',
    currency_code: 'RUB',
    currency_symbol: '₽',
    flag: '//cdn.shopify.com/s/assets/flags/ru-6963edca3433f981a1b431600ddee98cc8451da01629c9bf394835a859291bf1.svg',
  },
  RW: {
    currency: 'Rwanda Franc',
    country: 'Rwanda',
    currency_code: 'RWF',
    currency_symbol: 'Fr',
    flag: '//cdn.shopify.com/s/assets/flags/rw-b754c3c7705c8fe8778dadfa53d699fe8225b070aa6d698e10ce2e14cb0ed9c9.svg',
  },
  ST: {
    currency: 'Dobra',
    country: 'Sao Tome and Principe',
    currency_code: 'STD',
    currency_symbol: 'Db',
    flag: '//cdn.shopify.com/s/assets/flags/st-2a60ae15e8283bf8b4c7cc10010134f9879a190f5d13908b06363d7dafb312bd.svg',
  },
  SA: {
    currency: 'Riyal',
    country: 'Saudi Arabia',
    currency_code: 'SAR',
    currency_symbol: 'ر.س',
    flag: '//cdn.shopify.com/s/assets/flags/sa-fcd2ba12b40a6274fcc752f0706cbdca180c32b54b16aef548b71eaaa08c789f.svg',
  },
  SC: {
    currency: 'Rupee',
    country: 'Seychelles',
    currency_code: 'SCR',
    currency_symbol: '₨',
    flag: '//cdn.shopify.com/s/assets/flags/sc-f2daa196b5cd641ce75ba77827f0409ae4e70ce8ef002e63527447faed966ac9.svg',
  },
  SL: {
    currency: 'Leone',
    country: 'Sierra Leone',
    currency_code: 'SLL',
    currency_symbol: 'Le',
    flag: '//cdn.shopify.com/s/assets/flags/sl-84063287da3e78722221088294e0712687a1d1253f44a64d7af08eb7f78861f7.svg',
  },
  SG: {
    currency: 'Dollar',
    country: 'Singapore',
    currency_code: 'SGD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/sg-5fdb715a18e2ea6f9708451ed8e6ffb76a4217be74140d367764fc2bc095983f.svg',
  },
  SK: {
    currency: 'EUROS',
    country: 'Slovakia (Slovak Republic)',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/sk-7b588350e9e58c72c948ad3d24c2e748aab007a0e07a7b672073ad3a58d5991c.svg',
  },
  SB: {
    currency: 'Solomon Islands Dollar',
    country: 'Solomon Islands',
    currency_code: 'SBD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/sb-fc5ef4948d6d695b1bb614a61fba0a3562ad39d49409898509a7e9d98c410f71.svg',
  },
  SO: {
    currency: 'Shilling',
    country: 'Somalia',
    currency_code: 'SOS',
    currency_symbol: 'Sh',
    flag: '//cdn.shopify.com/s/assets/flags/so-2cb90d8922733b823d9cd3de361f63f0d8f61335157978547d9718305c652826.svg',
  },
  ZA: {
    currency: 'Rand',
    country: 'South Africa',
    currency_code: 'ZAR',
    currency_symbol: 'R',
    flag: '//cdn.shopify.com/s/assets/flags/za-2bc4ce21e2f155186022d608ae17af208ac7929dae2975c8e8d3fe0567ecf761.svg',
  },
  LK: {
    currency: 'Rupee',
    country: 'Sri Lanka',
    currency_code: 'LKR',
    currency_symbol: 'Rs  රු',
    flag: '//cdn.shopify.com/s/assets/flags/lk-32c19b7c89621aa502cfa10d9ac68bac79aff958dec7a36577aeb25000114ec1.svg',
  },
  SD: {
    currency: 'Dinar',
    country: 'Sudan',
    currency_code: 'SDG',
    flag: '//cdn.shopify.com/s/assets/flags/sd-9fdbc80d9c14c98b5326e45e2e54ee336caa91935f318b6658fa02bd455e28f4.svg',
  },
  SR: {
    currency: 'Surinamese Guilder',
    country: 'Suriname',
    currency_code: 'SRD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/sr-75625d0edd7b19901ec4f72c023f389fa8b97913bb2cd2fa77819942fc7a4fe2.svg',
  },
  SZ: {
    currency: 'Lilangeni',
    country: 'Swaziland',
    currency_code: 'SZL',
    currency_symbol: 'L',
    flag: '//cdn.shopify.com/s/assets/flags/sz-92d32ce32d08e4c21ad37388cc11bf6f99250b5ae5ec0fc152b9cefe09447715.svg',
  },
  SE: {
    currency: 'Krona',
    country: 'Sweden',
    currency_code: 'SEK',
    currency_symbol: 'kr',
    flag: '//cdn.shopify.com/s/assets/flags/se-8dd8cf5605b332f5a7abcc1ac83452c8f22ca5befb74160a835f0ffde46bfe81.svg',
  },
  SY: {
    currency: 'Syrian Pound',
    country: 'Syrian Arab Republic',
    currency_code: 'SYP',
    currency_symbol: '£',
    flag: '//cdn.shopify.com/s/assets/flags/sy-f4d88efb4e76b18f1596ce1153462d530ff46d92bcd7a5e82b45eba279aa8144.svg',
  },
  TW: {
    currency: 'Dollar',
    country: 'Taiwan',
    currency_code: 'TWD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/tw-526a10ea3b6b5e10f2b4f503cda2934bda6ecd6f6893db9220dd6142cb29f346.svg',
  },
  TJ: {
    currency: 'Tajikistan Ruble',
    country: 'Tajikistan',
    currency_code: 'TJS',
    currency_symbol: 'ЅМ',
    flag: '//cdn.shopify.com/s/assets/flags/tj-9dc0cc9585d29dee01fe90ca31a0c577dea714393b1e38b0cba65104a983d820.svg',
  },
  TZ: {
    currency: 'Shilling',
    country: 'Tanzania',
    currency_code: 'TZS',
    currency_symbol: 'Sh',
    flag: '//cdn.shopify.com/s/assets/flags/tz-c3022d5c1c240c83479ffb29f575ba787ccb9e28143daf98cba002a1fb1757d5.svg',
  },
  TH: {
    currency: 'Baht',
    country: 'Thailand',
    currency_code: 'THB',
    currency_symbol: '฿',
    flag: '//cdn.shopify.com/s/assets/flags/th-ffe662e5a63774ffd37330c5a5b08336e788e68148c2f63e9bdc8ab9d98c9f88.svg',
  },
  TO: {
    currency: 'PaÕanga',
    country: 'Tonga',
    currency_code: 'TOP',
    currency_symbol: 'T$',
    flag: '//cdn.shopify.com/s/assets/flags/to-c281f572c6de0cc1f92136acf977298c7a8472d377a43e8475b4e76cf6a45263.svg',
  },
  TT: {
    currency: 'Trinidad and Tobago Dollar',
    country: 'Trinidad and Tobago',
    currency_code: 'TTD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/tt-f7628af308d4b6a5e30370d173ac6b8b1151dfea4415faeaf21965ed19b8b09d.svg',
  },
  TN: {
    currency: 'Tunisian Dinar',
    country: 'Tunisia',
    currency_code: 'TND',
    currency_symbol: 'د.ت',
    flag: '//cdn.shopify.com/s/assets/flags/tn-1d5ad3bb6e975fc7bfc718d6e04fd59da5381b6ea75eced8e14197e897fc1ee9.svg',
  },
  TR: {
    currency: 'Lira',
    country: 'Turkey',
    currency_code: 'TRY',
    currency_symbol: '₺',
    flag: '//cdn.shopify.com/s/assets/flags/tr-07c4650655213ff4393d7be2563d3e95eb26cdc0f08f208951e8cba9e860d062.svg',
  },
  TM: {
    currency: 'Manat',
    country: 'Turkmenistan',
    currency_code: 'TMT',
    currency_symbol: 'm',
    flag: '//cdn.shopify.com/s/assets/flags/tm-343dd2b01707a6539c32ef6b8e6e9c6740465049f507dc4c4ac21eadc3c9fccb.svg',
  },
  UG: {
    currency: 'Shilling',
    country: 'Uganda',
    currency_code: 'UGX',
    currency_symbol: 'Sh',
    flag: '//cdn.shopify.com/s/assets/flags/ug-0debf20222e989183ca2349363c9193224982d1d942e4c0b99e40a2c10d32b0f.svg',
  },
  UA: {
    currency: 'Hryvnia',
    country: 'Ukraine',
    currency_code: 'UAH',
    currency_symbol: '₴',
    flag: '//cdn.shopify.com/s/assets/flags/ua-b58d698f846fbffd708df03df9b6ef4613038935f064539d914e6e4754c4fb6c.svg',
  },
  AE: {
    currency: 'Dirham',
    country: 'United Arab Emirates',
    currency_code: 'AED',
    currency_symbol: 'د.إ',
    flag: '//cdn.shopify.com/s/assets/flags/ae-3cce5239a0c9c329a8f2f0e6f900a7d58e463bd79ff4dd8cc9f2d89e057777d4.svg',
  },
  UY: {
    currency: 'Peso',
    country: 'Uruguay',
    currency_code: 'UYU',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/uy-ba9858ec07e8aefb8ff27c8398dc213b80fd69379d834546231d48aa62c435bb.svg',
  },
  UZ: {
    currency: 'Som',
    country: 'Uzbekistan',
    currency_code: 'UZS',
    currency_symbol: "so'm",
    flag: '//cdn.shopify.com/s/assets/flags/uz-0123edd5c4db7c4b1044825299925de140f7929dd91ec3be2764980ee234d134.svg',
  },
  VU: {
    currency: 'Vatu',
    country: 'Vanuatu',
    currency_code: 'VUV',
    currency_symbol: 'Vt',
    flag: '//cdn.shopify.com/s/assets/flags/vu-cb29e23b988fe42de8c05858f8b7f4609bc9e6b9c96ab7a0b70ae10a4df09e11.svg',
  },
  VE: {
    currency: 'Bolivar',
    country: 'Venezuela',
    currency_code: 'VEF',
    currency_symbol: 'Bs.S.',
    flag: '//cdn.shopify.com/s/assets/flags/ve-94d27d5388c9ecd6afd677573c8eb50eead088ce86d031f19affbb10b8db1ed4.svg',
  },
  VN: {
    currency: 'Dong',
    country: 'Vietnam',
    currency_code: 'VND',
    currency_symbol: '₫',
    flag: '//cdn.shopify.com/s/assets/flags/vn-90fae192a098f9bda964d4c594a32e53c8fc3242f5d6591973bcf959c5c4d8b3.svg',
  },
  YE: {
    currency: 'Rial',
    country: 'Yemen',
    currency_code: 'YER',
    currency_symbol: '﷼',
    flag: '//cdn.shopify.com/s/assets/flags/ye-d9453819872a7de3c717436f9d1a7e6650a3bec2d72bdfc1f581b80cf6bcc278.svg',
  },
  ZM: {
    currency: 'Kwacha',
    country: 'Zambia',
    currency_code: 'ZMK',
    currency_symbol: 'ZK',
    flag: '//cdn.shopify.com/s/assets/flags/zm-873696887f25527b132857132aea373dda7d1de1e02803dcbbe784e382bcf7d8.svg',
  },
  ZW: {
    currency: 'Zimbabwe Dollar',
    country: 'Zimbabwe',
    currency_code: 'ZWD',
    currency_symbol: '$',
    flag: '//cdn.shopify.com/s/assets/flags/zw-22a4ab7855acfb0b92ae062631162e4a3b9944ace38a168eb383644e71a0a863.svg',
  },
  AX: {
    currency: 'Euro',
    country: 'Aland Islands',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/ax-d46239a9579497bc01912679a9ec11a38899af9741fbb50f520a1bb0075a4055.svg',
  },
  AO: {
    currency: 'Angolan kwanza',
    country: 'Angola',
    currency_code: 'AOA',
    currency_symbol: 'Kz',
    flag: '//cdn.shopify.com/s/assets/flags/ao-02cfc2219fd71679d14ef2c7afc3e6122ae0d7685e28281322aa8ea66d1dc4a8.svg',
  },
  AQ: {
    currency: 'Antarctican dollar',
    country: 'Antarctica',
    currency_code: 'AQD',
  },
  BA: {
    currency: 'Bosnia and Herzegovina convertible mark',
    country: 'Bosnia and Herzegovina',
    currency_code: 'BAM',
    flag: '//cdn.shopify.com/s/assets/flags/ba-adf57e4f70d15236df96717a20c40aa56d8da5dc897c118fad2b61664642d5c0.svg',
  },
  GH: {
    currency: 'Ghana cedi',
    country: 'Ghana',
    currency_code: 'GHS',
    currency_symbol: '₵',
    flag: '//cdn.shopify.com/s/assets/flags/gh-52bd561ba8efc114ad5eb0cdeeca772dc097457b480ecd1e04fb44fc38f24643.svg',
  },
  GG: {
    currency: 'British pound',
    country: 'Guernsey',
    currency_code: 'GBP',
    currency_symbol: '£',
    flag: '//cdn.shopify.com/s/assets/flags/gg-df748401d5d3ec0c564ccf54ff192a6bffb34e6af25dae6a379639269eedc2eb.svg',
  },
  IM: {
    currency: 'Manx pound',
    country: 'Isle of Man',
    currency_code: 'GBP',
    currency_symbol: '£',
    flag: '//cdn.shopify.com/s/assets/flags/im-eeb3c6dc669cddbb33432b2429d9c85242e77b353164a48a16466d05f935e0d2.svg',
  },
  ME: {
    currency: 'Euro',
    country: 'Montenegro',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/me-6e3e694cab938cc673f3380a3e2b5baafe2c795cd73c9a5c3708e054b6778b13.svg',
  },
  PS: {
    currency: 'Jordanian dinar',
    country: 'Palestinian Territory',
    currency_code: 'JOD',
    currency_symbol: 'E£',
    flag: '//cdn.shopify.com/s/assets/flags/ps-5ff1dd310f5ee66d301158ab56261ada3af2d99d26073023eaa09d5b8002d0b2.svg',
  },
  BL: {
    currency: 'Euro',
    country: 'Saint Barthelemy',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/bl-53a8a7d8c53d71036c24aebb45e0192f98cad78b4ea3cfdeb695bd2f9bf2b7f5.svg',
  },
  SH: {
    currency: 'Saint Helena pound',
    country: 'Saint Helena',
    currency_code: 'GBP',
    currency_symbol: '£',
    flag: '//cdn.shopify.com/s/assets/flags/sh-552b60ab5b2ab564591cf7591ec641e8eb071331603154e72da5f5ab80f2784a.svg',
  },
  MF: {
    currency: 'Netherlands Antillean guilder',
    country: 'Saint Martin (French part)',
    currency_code: 'ANG',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/mf-10d09cbc2a8b2219da141bbacc940ec8f45a55b36b0dd8f92d07c6c93331f85f.svg',
  },
  PM: {
    currency: 'Euro',
    country: 'Saint Pierre and Miquelon',
    currency_code: 'EUR',
    currency_symbol: '€',
    flag: '//cdn.shopify.com/s/assets/flags/pm-53a8a7d8c53d71036c24aebb45e0192f98cad78b4ea3cfdeb695bd2f9bf2b7f5.svg',
  },
  RS: {
    currency: 'Serbian dinar',
    country: 'Serbia',
    currency_code: 'RSD',
    currency_symbol: 'дин.',
    flag: '//cdn.shopify.com/s/assets/flags/rs-5787eba463522ac15c429e67c02fd8236d5d54022976e21069bd7cb257aa388b.svg',
  },
  USAF: {
    currency: 'US Dollar',
    country: 'US Armed Forces',
    currency_code: 'USD',
  },
}
