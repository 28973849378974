export const CONFIG = {
  // put config
  API_BASEURL: process.env.NEXT_PUBLIC_API_BASEURL as string,
  COOKIE_DOMAIN: process.env.NEXT_PUBLIC_COOKIE_DOMAIN as string,
  ENVIRONMENT: (process.env.NEXT_PUBLIC_VERCEL_ENV || 'development') as string,
  SEGMENT_ID: (process.env.NEXT_PUBLIC_SEGMENT_ID as string) || false,
  AMPLITUDE_ID: (process.env.NEXT_PUBLIC_AMPLITUDE_ID as string) || false,
  TAG_MANAGER_ID: process.env.NEXT_PUBLIC_TAG_MANAGER_ID || false,
  SHOPIFY_APP_BASEURL: process.env.NEXT_PUBLIC_SHOPIFY_APP_BASEURL as string,
  TRANSLATION_SERVICE_BASEURL:
    (process.env.TRANSLATION_SERVICE_BASEURL as string) || '',
  REPORT_MISSING_TRANSLATIONS:
    (process.env.REPORT_MISSING_TRANSLATIONS as string) === '1',
  SENTRY_DSN: (process.env.NEXT_PUBLIC_SENTRY_DSN as string) || '',
  STRIPE_KEY: process.env.NEXT_PUBLIC_STRIPE_KEY as string,
  STOREFRONT_API_BASEURL: process.env
    .NEXT_PUBLIC_STOREFRONT_API_BASEURL as string,
  OPTIMIZELY_ID: (process.env.NEXT_PUBLIC_OPTIMIZELY_ID as string) || '',
  GOOGLE_OPTIMIZE_ID:
    (process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_ID as string) || '',
  BRAND_PUBLIC_KEY: (process.env.NEXT_PUBLIC_BRAND_PUBLIC_KEY as string) || '',
  BRAND_NAME: process.env.NEXT_PUBLIC_BRAND_NAME as string,
  INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_ID as string,
  ZENDESK_CHAT_API_KEY:
    (process.env.NEXT_PUBLIC_ZENDESK_CHAT_API_KEY as string) || '',
  STAMPED_STORE_URL: process.env.NEXT_PUBLIC_STAMPED_STORE_URL as string,
  STAMPED_API_KEY: process.env.NEXT_PUBLIC_STAMPED_API_KEY as string,
  ENABLE_ACCOUNT_SYSTEM_WARRANTY:
    (process.env.NEXT_PUBLIC_ENABLE_ACCOUNT_SYSTEM_WARRANTY as string) ||
    'disabled',
  ENABLE_ACCOUNT_SYSTEM_SUBSCRIPTION:
    (process.env.NEXT_PUBLIC_ENABLE_ACCOUNT_SYSTEM_SUBSCRIPTION as string) ||
    'disabled',
  ENABLE_ACCOUNT_SYSTEM_GIFT_CARD:
    (process.env.NEXT_PUBLIC_ENABLE_ACCOUNT_SYSTEM_GIFT_CARD as string) ||
    'enabled',
  BLOG_ID: (process.env.NEXT_PUBLIC_BLOG_ID as string) || '',
  GOOGLE_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_API_KEY || '',
  COOP_PUBLISHER_ID: process.env.NEXT_PUBLIC_COOP_PUBLISHER_ID || '',
  APP_URL: (process.env.NEXT_PUBLIC_APP_URL as string) || '',
  REFERRAL_ACCOUNT_SYSTEM_SUBSCRIPTION:
    (process.env.NEXT_PUBLIC_REFERRAL_ACCOUNT_SYSTEM_SUBSCRIPTION as string) ||
    'disabled',
  STORE_ROCKET_ACCOUNT_ID:
    (process.env.NEXT_PUBLIC_STOREROCKET_ACCOUNT_ID as string) || '',
  BAZAAR_CLIENT: process.env.NEXT_PUBLIC_BAZAAR_CLIENT || '',
  BAZAAR_SIDE_ID: process.env.NEXT_PUBLIC_BAZAAR_SIDE_ID || '',
  ENABLE_REDEEM_GIFT_CARD_AT_CHECKOUT:
    process.env.NEXT_PUBLIC_ENABLE_REDEEM_GIFT_CARD_AT_CHECKOUT || false,
  ONE_TRUST_TEMPLATE_URL:
    (process.env.NEXT_PUBLIC_ONE_TRUST_TEMPLATE_URL as string) || '',
  ONE_TRUST_TEMPLATE_DOMAIN:
    (process.env.NEXT_PUBLIC_ONE_TRUST_TEMPLATE_DOMAIN as string) || '',
  ONE_TRUST_CONSENT_URL:
    (process.env.NEXT_PUBLIC_ONE_TRUST_CONSENT_URL as string) || '',
  ENABLE_LOYALTY_DISCOUNT:
    (process.env.NEXT_PUBLIC_ENABLE_LOYALTY_DISCOUNT as string) || false,
  SHOPIFY_PERMANENT_URL:
    (process.env.NEXT_PUBLIC_SHOPIFY_PERMANENT_URL as string) || '',
  PANGAEA_ANALYTICS_SCRIPT_URL:
    (process.env.NEXT_PUBLIC_PANGAEA_ANALYTICS_SCRIPT_URL as string) || '',
  SUBSCRIPTION_TERMS_ENABLED_COUNTRIES:
    (process.env.NEXT_PUBLIC_SUBSCRIPTION_TERMS_ENABLED_COUNTRIES as string) ||
    '',
  SUBSCRIPTION_TERMS_PLACEMENT:
    (process.env.NEXT_PUBLIC_SUBSCRIPTION_TERMS_PLACEMENT as string) || '',
  ENABLE_YOUSHD: (process.env.NEXT_PUBLIC_ENABLE_YOUSHD as string) || '',
  PANDECTES_STORE_ID:
    (process.env.NEXT_PUBLIC_PANDECTES_STORE_ID as string) || '',
  SHIPPING_DELAYED_COUNTRIES:
    (process.env.NEXT_PUBLIC_SHIPPING_DELAYED_COUNTRIES as string) || '',
  SHOPIFY_APP_URL: (process.env.NEXT_PUBLIC_SHOPIFY_APP_URL as string) || '',
} as const
