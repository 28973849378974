import config from '../../core/config'
import { AnyDict } from '../../utils/types'
import { trackExperimentActivated } from './events'

import { layerPush } from 'modules/analytics/functions/track'
import { underscorize } from 'utils/string'

export const optimizelyPush = (data: AnyDict) => {
  window['optimizely'] = window['optimizely'] || []
  window['optimizely'].push(data)
}

/**
 * This activates a "page" that is setup for manual activation
 * https://help.optimizely.com/Build_Campaigns_and_Experiments/Activate_pages_in_Optimizely_X#Manually
 * https://docs.developers.optimizely.com/web/docs/page
 *
 * This is useful for triggering specific experiments manually that are associated with this page
 */
export const manuallyActivateOptimizelyPage = async (
  pageName: string
): Promise<void> => {
  return new Promise((resolve) => {
    optimizelyPush({
      type: 'page',
      pageName,
    })
    // set timeout is used to give the optimizely page activation time to "activate" and call the variation code
    setTimeout(() => {
      resolve()
    }, 0)
  })
}

export const manuallyActivateGoogleOptimizeExperiment = (
  experimentApiName: string
): Promise<void> => {
  return layerPush({
    event: experimentApiName,
  })
}

export const activateOptimizelyPage = async (
  pageName: string
): Promise<void[]> => {
  return Promise.all([
    manuallyActivateOptimizelyPage(pageName),
    manuallyActivateGoogleOptimizeExperiment(pageName),
  ])
}

// We would use this to filter result data sent by internal team and real customers
export function initOptimizelyUserEnvironment() {
  optimizelyPush({
    type: 'user',
    attributes: {
      environment: config('ENVIRONMENT'),
    },
  })
}

export const onOptimizelyCampaignDecided = ({ data }) => {
  const experimentName = data.campaign.name
  const experimentId = data.decision.experimentId
  if (experimentId) {
    const variation = (window as any).optimizely.get('state').getVariationMap()[
      experimentId
    ]
    if (!variation) return
    trackExperimentActivated(underscorize(experimentName), variation.name)
  }
}
