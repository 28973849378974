import 'modules/i18n'
import 'promise-polyfill'

import { ChakraProvider, CSSReset } from '@chakra-ui/core'
import { AccountSystemProvider } from '@pangaea-holdings/pangaea-account'
import { AuthProvider } from '@pangaea-holdings/pangaea-auth'
import {
  CheckoutProvider,
  isSupportedCurrency,
  KlarnaScriptTag,
} from '@pangaea-holdings/pangaea-checkout'
import * as Sentry from '@sentry/node'
import { NextPageContext } from 'next'
import cookies from 'next-cookies'
import { Context, createWrapper, MakeStore } from 'next-redux-wrapper'
import Head from 'next/head'
import React, { useEffect, useMemo } from 'react'

import config from '../core/config'
import getAppTheme from '../design/theme'
import { useAccountAuth } from '../modules/account/hooks/useAccountAuth'
import AnalyticsScriptTag from '../modules/analytics/components/AnalyticsScriptTag'
import ManualAnalyticsTags from '../modules/analytics/components/ManualAnalyticsTags'
import AnalyticsProvider from '../modules/analytics/context/provider'
import { CURRENCY_COOKIE } from '../modules/cart/constants'
import { UpdateCartCurrencies } from '../modules/cartv2/components/UpdateCartCurrencies'
import GoogleMapScriptTag from '../modules/google/GoogleMapScriptTag'
import { initializeLanguage } from '../modules/i18n/actions'
import detectLanguage from '../modules/i18n/functions/detectLanguage'
import CurrencyDetector from '../modules/lumin/components/CurrencyDetector'
import HeadPreload from '../modules/lumin/components/HeadPreload'
import { useLoadDatepickerCss } from '../modules/lumin/hooks/useLoadDatePickerCss'
import lifecycle from '../modules/lumin/listeners/lifecycle'
import ExperimentScriptTag from '../modules/optimizely/components/ExperimentScriptTag'
import { ErrorBoundary } from '../modules/shared/components/ErrorBoundary'
import { AppPropsWithLayout } from '../modules/shared/types'
import { AppState } from '../redux/rootReducer'
import { createStore } from '../redux/store'
import { isServerRequest } from '../utils/nextjs'

import OneTrustScriptTag from 'modules/analytics/components/OneTrustScriptTag'
import PandectesScriptTag from 'modules/analytics/components/PandectesScriptTag'
import { getCheckoutRoutes } from 'modules/checkout/checkoutRoutes'
import { LanguageResources } from 'modules/i18n/types'
import { checkIsShopifyStore } from 'modules/lumin/hooks/useIsShopifyStore'
import PageSurvey from 'modules/touchpointQuiz/PageSurvey'

/* import from lumin to make sure listeners have been added at this point */

if (config('SENTRY_DSN')) {
  Sentry.init({
    release: process.env.COMMIT_SHA,
    enabled: true,
    dsn: config('SENTRY_DSN'),
  })
}

interface LuminAppProps extends AppPropsWithLayout {
  lang: string
  currency: string
  translations: LanguageResources
  isShopifyStoreDomain?: boolean
}

// first load
if (process.browser) {
  lifecycle.handleFirstLoad()
}

export function LuminApp({
  lang,
  currency,
  translations,
  Component,
  pageProps,
  isShopifyStoreDomain,
  router,
  ...restProps
}: LuminAppProps) {
  // read account logged in status from cookie
  useAccountAuth()

  useLoadDatepickerCss()
  // Pull out the error to report to sentry
  // Typscript types are mssed up, see: https://github.com/vercel/next.js/issues/8592
  const err = (restProps as any).err

  const i18nLoaded = React.useRef<boolean>(false)
  if (!i18nLoaded.current && translations) {
    initializeLanguage(lang, translations)
    i18nLoaded.current = true
  }

  const shouldUseShopifyTheme =
    isShopifyStoreDomain !== undefined
      ? isShopifyStoreDomain
      : typeof window !== 'undefined' &&
        checkIsShopifyStore(location.host, router.query.store as string)

  const store = shouldUseShopifyTheme ? 'shopify' : 'next'

  const theme = useMemo(() => getAppTheme(store), [store])

  const getLayout = Component.getLayout ?? ((page) => page)

  useEffect(() => {
    if (
      router.pathname.indexOf('/checkout') === -1 &&
      router.pathname.search(
        /\/(pages\/free-trial-classic-maintenance-set|pages\/why-men-love-lumin-bab|pages\/why-men-love-lumin|trial-offer\/anti-fatigue-essentials-set|pages\/free-trial|pages\/why-men-love-lumin-skip-ft-page|pages\/anti-fatigue-essentials-set-recommendation|pages\/free-trial-anti-fatigue-essentials-set|pages\/offers-subscriptions-1)/gi
      ) !== -1
    ) {
      router.replace('/products', {
        query: router.query,
        pathname: '/products',
      })
    }
  }, [router.pathname])

  return (
    <ChakraProvider theme={theme}>
      <AnalyticsProvider>
        <AuthProvider>
          <CheckoutProvider
            checkoutRoutes={getCheckoutRoutes(store)}
            currency={currency}
          >
            <AccountSystemProvider>
              <UpdateCartCurrencies />
              <CSSReset />
              <Head>
                <HeadPreload />
                <link rel="shortcut icon" href="/favicon.png" />
                <meta name="robots" content="noindex" />
                <KlarnaScriptTag />
              </Head>
              <OneTrustScriptTag store={store} />
              <PandectesScriptTag store={store} />
              <ManualAnalyticsTags />
              <ExperimentScriptTag language={lang} />
              <GoogleMapScriptTag />
              {process.browser && <AnalyticsScriptTag />}
              <ErrorBoundary>
                <CurrencyDetector />
                {getLayout(<Component {...pageProps} err={err} />, { store })}
                <PageSurvey />
              </ErrorBoundary>
            </AccountSystemProvider>
          </CheckoutProvider>
        </AuthProvider>
      </AnalyticsProvider>
    </ChakraProvider>
  )
}

LuminApp.getInitialProps = async ({ ctx }: { ctx: NextPageContext }) => {
  // no props need to be sent back down for SPA navigates
  // only initial page render

  if (!isServerRequest(ctx) || process.browser) {
    return {}
  }

  const lang = detectLanguage(ctx)
  const allCookies = cookies(ctx)
  const selectedCurrency =
    (ctx.query.currency as string) || allCookies[CURRENCY_COOKIE] || ''
  const currency: string = isSupportedCurrency(selectedCurrency)
    ? selectedCurrency
    : 'USD'

  let translations = {}

  let isShopifyStoreDomain = false

  try {
    // only fetch translations on the first request, subsequent requests
    // will have translations in memory
    if (lang && lang !== 'en') {
      const { fetchTranslations } = await import(
        '../modules/i18n/fetchTranslation'
      )
      translations = await fetchTranslations(lang, ctx.req?.headers.host)
    }
    isShopifyStoreDomain = checkIsShopifyStore(
      ctx.req?.headers.host,
      ctx.query.store as string
    )

    const isNotLocalhost =
      location.hostname !== 'localhost' && location.hostname !== '127.0.0.1'

    if (
      isShopifyStoreDomain &&
      ctx.res &&
      !ctx.req?.url?.includes('checkout') &&
      isNotLocalhost
    ) {
      ctx.res.setHeader('location', 'https://www.luminskin.com')
      ctx.res.statusCode = 302
    }
  } catch (e) {
    // swallow for now because pre-rendering doesnt like this
  }

  return {
    translations,
    lang,
    isShopifyStoreDomain,
    currency: currency.toUpperCase(),
  }
}

// export function reportWebVitals(metric: NextWebVitalsMetric) {
//   // console.log(metric)
// }

const makeStore: MakeStore<AppState> = (context: Context) => createStore()

export default createWrapper(makeStore).withRedux(LuminApp)
