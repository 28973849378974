import {
  CheckoutContext,
  updateCartCurrencies,
  CURRENCY_CODE_LIST,
} from '@pangaea-holdings/pangaea-checkout'
import { useContext, useEffect } from 'react'

import { TOP_CURRENCIES } from '../../../utils/currency'

export const UpdateCartCurrencies = () => {
  const { dispatch } = useContext(CheckoutContext)

  useEffect(() => {
    sortOutCurrencies()
  }, [])

  const sortOutCurrencies = () => {
    const newCurrencies = { ...TOP_CURRENCIES, dd: 'divider' }

    Object.keys(CURRENCY_CODE_LIST).forEach((key) => {
      if (!newCurrencies[key]) {
        newCurrencies[key] = CURRENCY_CODE_LIST[key]
      }
    })

    dispatch(updateCartCurrencies(newCurrencies))
  }

  return null
}
