export const ACCOUNT_LOAD_AUTH = 'ACCOUNT_LOAD_AUTH'

export interface AccountLoadAuth {
  type: typeof ACCOUNT_LOAD_AUTH
  payload: {
    token: string
    userId: string
  } | null
}

export type AccountActionTypes = AccountLoadAuth
