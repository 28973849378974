import { combineReducers, ActionCreator } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { AccountActionTypes } from '../modules/account/actionTypes'
import { AccountReducerState, accountReducer } from '../modules/account/reducer'
import { OrderConfirmationActionTypes } from '../modules/checkout/actionTypes'
import {
  OrderConfirmationState,
  orderConfirmationReducer,
} from '../modules/checkout/reducer'
import { QuizActionTypes } from '../modules/listiquiz/actionTypes'
import {
  quizAnswersReducer,
  answeredQuestionsReducer,
  singleFreeTrialSetReducer,
} from '../modules/listiquiz/reducer'
import {
  QuizAnswers,
  AnsweredQuestion,
  SingleFreeTrial,
} from '../modules/listiquiz/types'
import { ProductsActionTypes } from '../modules/products/actionTypes'
import {
  productsReducer,
  ProductsReducerState,
} from '../modules/products/reducer'

import { BusinessModelActionTypes } from 'modules/discounted-box/actions'
import { businessModelReducer } from 'modules/discounted-box/reducer'
import { PersonalizedQuizActionTypes } from 'modules/personalizedQuiz/actionTypes'
import { quizChoiceReducer } from 'modules/personalizedQuiz/reducer'
import { PersonalizedQuizState } from 'modules/personalizedQuiz/types'
import { BusinessModel } from 'modules/shared/types'

export const rootReducer = combineReducers({
  account: accountReducer,
  products: productsReducer,
  quizAnswers: quizAnswersReducer,
  personalizedQuizChoice: quizChoiceReducer,
  answeredQuestions: answeredQuestionsReducer,
  singleFreeTrialSet: singleFreeTrialSetReducer,
  orderConfirmationState: orderConfirmationReducer,
  businessModelState: businessModelReducer,
})

export type AppState = {
  account: AccountReducerState
  products: ProductsReducerState
  quizAnswers: QuizAnswers
  personalizedQuizChoice: PersonalizedQuizState
  answeredQuestions: AnsweredQuestion
  singleFreeTrialSet: SingleFreeTrial
  orderConfirmationState: OrderConfirmationState
  businessModelState: BusinessModel | null
}

export type AppActionTypes =
  | AccountActionTypes
  | QuizActionTypes
  | ProductsActionTypes
  | OrderConfirmationActionTypes
  | PersonalizedQuizActionTypes
  | BusinessModelActionTypes

export type ThunkActionCreator<R> = ActionCreator<
  ThunkAction<R, AppState, void, AppActionTypes>
>
