import {
  setStorefrontUrl,
  setBrandPublicKey,
  setBrandServicesUrl,
} from '@pangaea-holdings/pangaea-checkout'
import fetch from 'isomorphic-unfetch'

import config from '../../core/config'

const BASE_URL = config('API_BASEURL')

const fetchCacheMap: Record<string, Promise<Response> | undefined> = {}

setBrandPublicKey(config('BRAND_PUBLIC_KEY'))
setStorefrontUrl(config('STOREFRONT_API_BASEURL'))
setBrandServicesUrl(BASE_URL)

export const apiFetch = (
  path: string,
  opts?: RequestInit
): Promise<Response> => {
  return fetch(`${BASE_URL}${path}`, opts)
}

export const apiFetchJson = async (path: string, opts?: RequestInit) => {
  const req = await fetch(`${BASE_URL}${path}`, opts)
  return await req.json()
}

const cachedFetch = (url: string, opts?: RequestInit, cached?: boolean) => {
  let promise: Promise<Response>

  const method = (opts?.method || 'get').toLowerCase()

  if (method !== 'get' || !cached) {
    return fetch(url, opts)
  }

  const cacheKey = url

  if (fetchCacheMap[cacheKey]) {
    promise = fetchCacheMap[cacheKey]!
  } else {
    promise = fetch(url, opts)
    fetchCacheMap[cacheKey] = promise
  }

  return promise.then((res) => res.clone())
}

export async function apiRouteFetch<T>(
  path: string,
  opts?: RequestInit
): Promise<T> {
  const req = await cachedFetch(`/api${path}`, opts, true)
  return (await req.json()) as T
}
