import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import config from '../../../core/config';

export const checkIsShopifyStore = (hostname, query?: string | null) => {
  const shopifyStoreHostDomains = [
    'checkout-staging.luminskin.com',
    'checkout.luminskin.com',
    'localhost:3000',
    'e81a-102-67-9-207.ngrok-free.app',
  ]

  const env = config('ENVIRONMENT')
  const isQueryOverideEnabled = env.toLowerCase() !== 'production'

  return (
    shopifyStoreHostDomains.includes(hostname) ||
    (isQueryOverideEnabled && query === 'shopify')
  )
}

export const useIsShopifyStore = () => {
  const router = useRouter()

  const [isShopifyStore, setIsShopifyStore] = useState(false)

  useEffect(() => {
    setIsShopifyStore(
      checkIsShopifyStore(window.location.host, router.query.store as string)
    )
  }, [router.query])

  return { isShopifyStore }
}
