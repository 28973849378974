import {
  CheckoutContext,
  updateCurrency,
  getUserCountryCode,
  shippingCountrySelected,
  isSupportedCurrency,
  getCookie,
  loadCountries,
  SHIPPING_COUNTRY_COOKIE,
} from '@pangaea-holdings/pangaea-checkout'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

import { removeQueryParam } from '../../../utils/removeQueryParam'
import { CURRENCIES } from '../currencies'

const isShippableCountry = (countries, countryId) => {
  return countries.some((c) => c.id === countryId && c.isShippable)
}

export type DetectAndSetCurrency = (country: string) => Promise<void>

export function useDetectAndSetCurrency() {
  const { dispatch } = useContext(CheckoutContext)
  const router = useRouter()

  useEffect(() => {
    const shippingCountriesPromise = dispatch(loadCountries())

    const setDetectedCountryAndCurrency = async (id) => {
      dispatch(shippingCountrySelected(id))
      await dispatch(updateCurrency(CURRENCIES[id].currency_code))
    }

    const detectAndSet = async (country: string | null) => {
      const countries = await shippingCountriesPromise

      const detectedCountry = country && CURRENCIES[country]

      const countryParam = router.query.country as string

      const savedCountry = getCookie(SHIPPING_COUNTRY_COOKIE)

      if (countryParam && isShippableCountry(countries, countryParam)) {
        setDetectedCountryAndCurrency(countryParam)
        return
      }

      if (savedCountry && isShippableCountry(countries, savedCountry)) {
        setDetectedCountryAndCurrency(savedCountry)
        return
      }

      if (detectedCountry && isShippableCountry(countries, country)) {
        setDetectedCountryAndCurrency(country)
        return
      }

      setDetectedCountryAndCurrency('US')
    }

    getUserCountryCode().then(detectAndSet)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])
}
