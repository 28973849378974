import {
  ACTIONS_TYPES,
  QuizActionTypes,
} from '../actionTypes'
import { SingleFreeTrial } from '../types'

const singleFreeTrialInitialState: SingleFreeTrial = {
  status: false,
}

export default function singleFreeTrialSetReducer(
  state = singleFreeTrialInitialState,
  action: QuizActionTypes,
): SingleFreeTrial {
  switch (action.type) {
    case ACTIONS_TYPES.ENABLE_SINGLE_FREE_TRIAL_BUNDLE: {
      return { ...state, status: true }
    }
    case ACTIONS_TYPES.DISABLE_SINGLE_FREE_TRIAL_BUNDLE: {
      return { ...state, status: false }
    }
    default: {
      return state
    }
  }
}
