import { returnPolicyPage } from 'modules/shared/constants/general'

const shopifyCheckoutRoutes = {
  privacyPolicy: () => 'https://www.luminskin.com/policies/privacy-policy',
  returnPolicy: () => returnPolicyPage,
  termsOfService: () => 'https://www.luminskin.com/policies/terms-of-service',
  checkout: (params) => {
    const couponQuery = params?.coupon
      ? `?override_coupon=${params?.coupon}`
      : ''
    return `/checkout/${params?.hash}${couponQuery}`
  },
  checkoutConfirmation: () => '',
  productsPage: () => 'https://www.luminskin.com/collections/all',
  homePage: () => 'https://www.luminskin.com/',
  pageNotFound: () => 'https://www.luminskin.com/404',
  subscriptions: () => 'https://www.luminskin.com/',
}

export const getCheckoutRoutes = (store) => {
  if (store === 'shopify') {
    return shopifyCheckoutRoutes
  }
  return undefined
}
