import { COOKIE_LANG_KEY } from '@pangaea-holdings/pangaea-auth'
import {
  logger,
  getCookie,
  setCookie,
} from '@pangaea-holdings/pangaea-checkout'
import { COOKIE_LOOKUP_KEY_LANG } from '@unly/universal-language-detector'
import fetch from 'isomorphic-unfetch'

import config from '../../core/config'
import MissingTranslationReporter from './MissingTranslationReporter'
import { LanguageResources } from './types'

import i18n from './'

/**
 * Sets the language globally and loads any resources for that language
 */
export async function initializeLanguage(
  lang: string,
  resources: LanguageResources
) {
  // set the cookie so that subsequent pages remember this choice
  // language cookie is used by lumin -shopify
  const language =
    lang || getCookie(COOKIE_LOOKUP_KEY_LANG) || getCookie('language')
  i18n.changeLanguage(language)

  // only add languages when resources are available or language is english (this means we'll always use our default english)
  if (Object.keys(resources).length !== 0 || lang === 'en') {
    Object.keys(resources).forEach((ns) => {
      const values = resources[ns]
      i18n.addResourceBundle(language, ns, values)
    })
  } else {
    await initializeFallbackLanguage(lang)
    logger.error('CDN Error, defaulting to fallback', {
      language: lang,
    })
  }
  if (process.browser) {
    setCookie(COOKIE_LOOKUP_KEY_LANG, lang, 365)
    setCookie(COOKIE_LANG_KEY, lang, 365)
  }
}

export function addResourceToBundle(
  resource: LanguageResources,
  fileName: string,
  language: string
) {
  const namespace = fileName.replace('.json', '')
  const values = JSON.parse(JSON.stringify(resource))
  i18n.addResourceBundle(language, namespace, values)
}

/**
 * Sets the language globally and loads any resources from local files for that language
 */
export async function initializeFallbackLanguage(lang: string) {
  const language =
    lang || getCookie(COOKIE_LOOKUP_KEY_LANG) || getCookie('language')

  i18n.changeLanguage(language)

  const domain = config('APP_URL')

  const files = await (await fetch(domain + '/locales/namespaces.json')).json()

  await Promise.all(
    files.namespaces.map(async (namespace) => {
      try {
        const resource = await (
          await fetch(
            domain + '/locales/' + language + '/' + namespace + '.json'
          )
        ).json()
        addResourceToBundle(resource, namespace, language)
      } catch (error) {
        logger.error('invalid namespace', {
          language: lang,
          namespace,
        })
      }
    })
  )

  if (process.browser) {
    setCookie(COOKIE_LOOKUP_KEY_LANG, lang, 365)
    setCookie('language', lang, 365)
  }
}

export function changeLanguage(lang: string) {
  i18n.changeLanguage(lang)
  // URL is not supported in op_mini all
  // eslint-disable-next-line compat/compat
  const params = new URL(window.location.toString()).searchParams
  params.set(COOKIE_LANG_KEY, lang)
  window.location.assign(`${window.location.pathname}?${params.toString()}`)
}

export const onMissingKey = (() => {
  let missingKeyReporter: MissingTranslationReporter

  return async function (
    lang: string,
    namespace: string,
    key: string,
    defaultValue: string
  ): Promise<void> {
    if (typeof window === 'undefined') {
      // only report if on browser
      return
    }
    if (!config('REPORT_MISSING_TRANSLATIONS')) {
      return
    }
    if (!missingKeyReporter) {
      missingKeyReporter = new MissingTranslationReporter()
    }

    missingKeyReporter.report({
      lang,
      namespace,
      key,
      master: defaultValue,
      metadata: {
        location: window.location.toString(),
      },
    })
  }
})()

export function getLanguage(): string {
  return i18n.language
}
