import Head from 'next/head'
import React, { useRef } from 'react'

import config from '../../../core/config'

const OneTrustScriptTag: React.FC<{ store: string }> = ({ store = 'next' }) => {
  const consentUrl = config('ONE_TRUST_CONSENT_URL')
  const templateUrl = config('ONE_TRUST_TEMPLATE_URL')
  const templateDomain = config('ONE_TRUST_TEMPLATE_DOMAIN')

  const isLoaded = useRef(false)

  const isShopifyStore = store === 'shopify'

  // Prevent these scripts from loading multiple times
  if (isLoaded.current) {
    return null
  }
  isLoaded.current = true

  if (!isShopifyStore) {
    return null
  }

  return (
    <>
      <Head key="onetrust_script">
        {consentUrl && <script type="text/javascript" src={consentUrl} />}
        {templateUrl && templateDomain && (
          <script
            src={templateUrl}
            data-document-language="true"
            type="text/javascript"
            data-domain-script={templateDomain}
          />
        )}
      </Head>
    </>
  )
}

export default OneTrustScriptTag
