import {
  OrderConfirmationActionTypes,
  ORDER_CONFIRMATION_ACTIONS,
} from './actionTypes'

export interface OrderConfirmationState {
  showOffers: boolean
}

const initialState: OrderConfirmationState = {
  showOffers: false,
}

export function orderConfirmationReducer(
  state = initialState,
  action: OrderConfirmationActionTypes
): OrderConfirmationState {
  switch (action.type) {
    case ORDER_CONFIRMATION_ACTIONS.SHOW_POST_PURCHASE_OFFERS:
      return {
        ...state,
        showOffers: action.payload,
      }
    default:
      return state
  }
}
